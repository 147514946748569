import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTimer } from "hooks/useTimer";
import { countries } from "james/country";
import { CountryCode } from "libphonenumber-js";
import { MobileNumberVerificationStep } from "../../MobileNumberVerification";
import { CaptureMobileNumberCard } from "../MobileNumberCaptureCard/CaptureMobileNumberCard";
import { VerifyMobileNumberCard } from "../VerifyMobileNumberCard/VerifyMobileNumberCard";
import DialogContent from "@mui/material/DialogContent";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useApplicationContext } from "context/Application/Application";
import { useFirebaseContext } from "context/Firebase";
import { UserState } from "james/user/User";
import { useLocation } from "react-router-dom";
import { usePersonContext } from "context/Person/PersonService";
import { ClientKYCStatus } from "james/client";
import config from "react-global-configuration";

export enum ReturningUserStep {
  showConfirmation = "showConfirmation",
  showCaptureDialog = "showCaptureDialog",
}

export function MobileNumberVerificationOutstanding() {
  const theme = useTheme();
  const { userAuthenticated, loginClaims, myClient } = useApplicationContext();
  const { myPerson } = usePersonContext();
  const { firebaseAuthenticated } = useFirebaseContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const { timerValue, timerCompleted, startTimer, stopTimer } = useTimer();
  const [phoneNumberVeriified, setPhoneNumberVeriified] = useState(true);
  const [countryCode, setCountryCode] = useState(
    countries.filter((c) => c.value === "ZA")[0].value as CountryCode,
  );
  const [dialogView, setDialogView] = useState(
    ReturningUserStep.showConfirmation,
  );
  const location = useLocation();
  const [viewState, setViewState] = useState<MobileNumberVerificationStep>(
    MobileNumberVerificationStep.sendVerificationCode,
  );
  const [openDialog, setOpenDialog] = useState(true);

  const setOTPValues = (): [number, boolean] => {
    return [timerValue, timerCompleted];
  };

  useEffect(() => {
    const contacts = myPerson.getContactdetails()?.getCellphonenumber();

    if (!contacts) {
      return;
    }

    setPhoneNumberVeriified(contacts.getVerified());
  }, [myPerson]);

  const showOutstandingVerification = useMemo(
    () =>
      loggedIn &&
      loginClaims.userState === UserState.Registered &&
      myClient?.kycStatus === ClientKYCStatus.VerifiedStatus &&
      !location.pathname.includes("/verify-mobile-number") &&
      !phoneNumberVeriified &&
      config.get("allowMFAVerification"),
    [loggedIn, myClient, loginClaims, location, myPerson, phoneNumberVeriified],
  );

  if (!showOutstandingVerification) {
    return;
  }

  if (showOutstandingVerification && myPerson) {
    return (() => {
      switch (dialogView) {
        case ReturningUserStep.showConfirmation:
          return (
            <>
              {isMobile ? (
                <Drawer open={openDialog} anchor="bottom">
                  <DialogTitle sx={{ height: "52px" }}>
                    <Typography fontSize="16px">
                      Number Verification Required
                    </Typography>
                    <Grid item>
                      <Tooltip title="Close" placement="top">
                        <span>
                          <IconButton
                            id="Verify-mobile-number-dialog-close-button"
                            size="small"
                            onClick={() => setOpenDialog(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </DialogTitle>
                  <DialogContent sx={{ padding: 3 }}>
                    <Typography
                      sx={(theme) => ({
                        padding: theme.spacing(2, 0, 25),
                        color: theme.palette.text.hint,
                      })}
                      fontSize="12px"
                      fontWeight="400"
                    >
                      To keep your account secure and improve your experience,
                      please verify your mobile number. This quick step helps us
                      confirm your identity and protect your account from
                      unauthorized access.
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 2,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                        size="large"
                      >
                        Remind me
                      </Button>
                      <Button
                        onClick={() =>
                          setDialogView(ReturningUserStep.showCaptureDialog)
                        }
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Verify now
                      </Button>
                    </Box>
                  </DialogContent>
                </Drawer>
              ) : (
                <Dialog open={openDialog}>
                  <DialogTitle sx={{ height: "52px" }}>
                    <Typography fontSize="16px">
                      Mobile Number Verification Required
                    </Typography>
                    <Grid item>
                      <Tooltip title="Close" placement="top">
                        <span>
                          <IconButton
                            id="Verify-mobile-number-dialog-close-button"
                            size="small"
                            onClick={() => setOpenDialog(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </DialogTitle>
                  <DialogContent sx={{ padding: 3 }}>
                    <Typography
                      sx={(theme) => ({
                        padding: theme.spacing(2, 0, 4),
                        color: theme.palette.text.hint,
                      })}
                      fontSize="12px"
                      fontWeight="400"
                    >
                      To keep your account secure and improve your experience,
                      please verify your mobile number. This quick step helps us
                      confirm your identity and protect your account from
                      unauthorized access.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        sx={(theme) => ({ mr: theme.spacing(2) })}
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                      >
                        Remind me later
                      </Button>
                      <Button
                        onClick={() =>
                          setDialogView(ReturningUserStep.showCaptureDialog)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Verify now
                      </Button>
                    </Box>
                  </DialogContent>
                </Dialog>
              )}
            </>
          );
        case ReturningUserStep.showCaptureDialog:
          return (
            <>
              {(() => {
                switch (viewState) {
                  case MobileNumberVerificationStep.sendVerificationCode:
                    return (
                      <CaptureMobileNumberCard
                        setViewState={setViewState}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        startTimer={startTimer}
                        firstTimeLogin={false}
                        isMobile={isMobile}
                      />
                    );

                  case MobileNumberVerificationStep.verifyCode:
                    return (
                      <VerifyMobileNumberCard
                        phoneNumber={phoneNumber}
                        countryCode={countryCode}
                        setOTPValues={setOTPValues}
                        stopTimer={stopTimer}
                        firstTimeLogin={false}
                        isMobile={isMobile}
                        setViewState={setViewState}
                      />
                    );
                }
              })()}
            </>
          );
      }
    })();
  }
}
