/**
 * @fileoverview gRPC-Web generated client stub for iam
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: iam/userReadService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var security_context_pb = require('../security/context_pb.js')

var iam_user_pb = require('../iam/user_pb.js')
const proto = {};
proto.iam = require('./userReadService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.iam.UserReadServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.iam.UserReadServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.iam.GetUserRequest,
 *   !proto.iam.User>}
 */
const methodDescriptor_UserReadService_GetUser = new grpc.web.MethodDescriptor(
  '/iam.UserReadService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.iam.GetUserRequest,
  iam_user_pb.User,
  /**
   * @param {!proto.iam.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  iam_user_pb.User.deserializeBinary
);


/**
 * @param {!proto.iam.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.iam.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.iam.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.iam.UserReadServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/iam.UserReadService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserReadService_GetUser,
      callback);
};


/**
 * @param {!proto.iam.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.iam.User>}
 *     Promise that resolves to the response
 */
proto.iam.UserReadServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/iam.UserReadService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserReadService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.iam.ListUsersRequest,
 *   !proto.iam.ListUsersResponse>}
 */
const methodDescriptor_UserReadService_ListUsers = new grpc.web.MethodDescriptor(
  '/iam.UserReadService/ListUsers',
  grpc.web.MethodType.UNARY,
  proto.iam.ListUsersRequest,
  proto.iam.ListUsersResponse,
  /**
   * @param {!proto.iam.ListUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.iam.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.iam.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.iam.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.iam.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.iam.UserReadServiceClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/iam.UserReadService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_UserReadService_ListUsers,
      callback);
};


/**
 * @param {!proto.iam.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.iam.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.iam.UserReadServicePromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/iam.UserReadService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_UserReadService_ListUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.iam.UnmaskUserRequest,
 *   !proto.iam.User>}
 */
const methodDescriptor_UserReadService_UnmaskUser = new grpc.web.MethodDescriptor(
  '/iam.UserReadService/UnmaskUser',
  grpc.web.MethodType.UNARY,
  proto.iam.UnmaskUserRequest,
  iam_user_pb.User,
  /**
   * @param {!proto.iam.UnmaskUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  iam_user_pb.User.deserializeBinary
);


/**
 * @param {!proto.iam.UnmaskUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.iam.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.iam.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.iam.UserReadServiceClient.prototype.unmaskUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/iam.UserReadService/UnmaskUser',
      request,
      metadata || {},
      methodDescriptor_UserReadService_UnmaskUser,
      callback);
};


/**
 * @param {!proto.iam.UnmaskUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.iam.User>}
 *     Promise that resolves to the response
 */
proto.iam.UserReadServicePromiseClient.prototype.unmaskUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/iam.UserReadService/UnmaskUser',
      request,
      metadata || {},
      methodDescriptor_UserReadService_UnmaskUser);
};


module.exports = proto.iam;

