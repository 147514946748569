import React, { Dispatch, SetStateAction } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  InputBase,
  Typography,
  Chip,
} from "@mui/material";
import {
  getCountryCallingCode,
  getCountries,
  AsYouType,
  CountryCode,
  isValidNumber,
} from "libphonenumber-js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { countries } from "james/country";

export interface PhoneNumberInputProps {
  countryCode: CountryCode;
  setCountryCode: Dispatch<SetStateAction<CountryCode>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  // only South Africa supported for now, remove to enable other countries
  disableCountrySelect: boolean;
  // optional to set background color based on use/need
  // leave not set to default to midnight
  inputBGColor?: string;
  setIsValidPhoneNumber: Dispatch<SetStateAction<boolean>>;
  isValidPhoneNumber: boolean;
}

export function PhoneNumberInput({
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
  disableCountrySelect,
  inputBGColor,
  setIsValidPhoneNumber,
  isValidPhoneNumber,
}: PhoneNumberInputProps) {
  const handleCountryChange = (value: CountryCode) => {
    setCountryCode(value);
    setPhoneNumber("");
  };

  const countryLabels: Record<string, string> = countries.reduce(
    (count, item) => {
      count[item.value] = item.label;
      return count;
    },
    {} as Record<string, string>,
  );

  const handlePhoneNumberChange = (value: string) => {
    // Note: this only makes it look pretty for ZA
    if (countryCode === "ZA") {
      // add a 0 if the number doesn't start with a 0
      // and just check that everything is number for
      // format number purpose
      const newValue =
        value.replace(/\D/g, "").length >= 1 &&
        value.replace(/\D/g, "")[0] !== "0"
          ? "0" + value
          : value.replace(/\D/g, "");
      const asYouType = new AsYouType(countryCode);
      // format the number as you type by country
      const formattedNumber = asYouType.input(newValue);
      // validate phone number format
      setIsValidPhoneNumber(isValidNumber(formattedNumber, countryCode));

      setPhoneNumber(
        value[0] === "0" ? formattedNumber : formattedNumber.slice(1),
      );
    } else {
      const asYouType = new AsYouType(countryCode);
      const formattedNumber = asYouType.input(value);
      setPhoneNumber(formattedNumber);
      setIsValidPhoneNumber(isValidNumber(formattedNumber, countryCode));
    }
  };

  const countriesDialCode = getCountries().map((countryCode) => ({
    code: countryCode,
    dialCode: `+${getCountryCallingCode(countryCode)}`,
    label: countryLabels[countryCode.toString()],
  }));

  return (
    <>
      <FormControl
        variant="outlined"
        sx={(theme) => ({
          minWidth: 100,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: `1px solid ${theme.palette.text.disabled}`,
          borderRadius: "5px",
          "&:has(.Mui-focused)": {
            border: isValidPhoneNumber
              ? `1px solid ${theme.palette.secondary.main}`
              : `1px solid ${theme.palette.error.main}`,
          },
        })}
        error={!isValidPhoneNumber}
      >
        <Select
          sx={{
            height: "36px",
            ml: 1,
            mr: 1,
          }}
          value={countryCode}
          onChange={(e) => handleCountryChange(e.target.value as CountryCode)}
          label="Country"
          variant="standard"
          disabled={disableCountrySelect}
          input={<InputBase />}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            slotProps: {
              paper: {
                className: "meshScroll",
                sx: (theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    width: "calc(100% - 48px)",
                    ml: 1,
                  },
                  [theme.breakpoints.up("sm")]: {
                    width: "344px",
                    ml: 15.5,
                  },
                  height: "150px",
                  mt: 0.5,
                }),
              },
            },
          }}
          renderValue={(country) => (
            <MenuItem key={country} value={country}>
              <Box
                sx={{
                  "& > img": {
                    m: 0,
                    flexShrink: 0,
                  },
                  display: "flex",
                }}
              >
                <img
                  loading="lazy"
                  width="24"
                  src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
                  alt=""
                />
              </Box>
            </MenuItem>
          )}
        >
          {countriesDialCode
            .sort((a, b) => +a.dialCode - +b.dialCode)
            .map((country) => (
              <MenuItem key={country.code} value={country.code}>
                <Box
                  sx={{
                    "& > img": {
                      mr: 2,
                      flexShrink: 0,
                    },
                    display: "flex",
                  }}
                >
                  <img
                    loading="lazy"
                    width="24"
                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {country.label}
                  <Chip
                    sx={(theme) => ({
                      ml: 2,
                      height: 1,
                      backgroundColor: theme.palette.custom.grapeLight,
                    })}
                    size="small"
                    label={country.dialCode}
                  />
                </Box>
              </MenuItem>
            ))}
        </Select>
        <Box
          className={"field-divider"}
          sx={(theme) => ({
            borderLeft: `1px solid ${theme.palette.text.disabled}`,
            height: 24,
            width: "2px",
          })}
        />
        <InputBase
          startAdornment={
            <Typography sx={{ mr: 1, mb: 0.2 }}>
              +{getCountryCallingCode(countryCode)}
            </Typography>
          }
          value={phoneNumber}
          margin="none"
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
          sx={{
            height: "50%",
            py: 0,
            pl: 1,
          }}
        />
        <InputLabel
          shrink
          sx={(theme) => ({
            "&.Mui-focused": {
              color: isValidPhoneNumber
                ? theme.palette.secondary.main
                : theme.palette.error.main,
            },
            backgroundColor: inputBGColor
              ? inputBGColor
              : theme.palette.custom.midnight,
            px: 0.5,
          })}
        >
          Contact Number
        </InputLabel>
      </FormControl>
      <Box
        sx={{
          height: "10px",
        }}
      >
        {!isValidPhoneNumber && (
          <Typography
            sx={{
              textAlign: "center",
              paddingTop: "4px",
            }}
            fontSize="12px"
            fontWeight="400"
            color="error"
            children="Invalid mobile number format"
          />
        )}
      </Box>
    </>
  );
}
