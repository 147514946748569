import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { KYCStatusCard } from "./KYCStatusCard/KYCStatusCard";
import { ProfileCard } from "./ProfileCard/ProfileCard";
import meshLogoNoWords from "assets/images/logo/meshLogoNoWords.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { LeaveDialog } from "./components/LeaveDialog";
import { TopBar } from "./components/TopBar";
import { Person } from "james/legal/person";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import background from "assets/images/background/background.png";
import { useErrorContext } from "context/Error";
import { ClientKind } from "james/client/Client";
import { usePersonContext } from "context/Person/PersonService";
import { personToFuture, personToPast } from "james/legal/person/Person";
import { AllowedPersonUpdatePaths } from "@mesh/common-js/src/legal/personWriteService_pb";

type LeaveFunc = () => void;

export const UserProfileDialog = (props: DialogProps) => {
  const [myPerson, setMyPerson] = useState<Person>(new Person());
  const { retrievePerson, updatePerson } = usePersonContext();
  const [loadingMyPerson, setLoadingMyPerson] = useState(false);
  const { myClient } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [editing, setEditing] = useState(0);
  const [leaveFunc, setLeaveFunc] = useState<LeaveFunc>(() => () => null);
  const [newPerson, setNewPerson] = useState<Person | undefined>(undefined);
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const { Banner } = useAppNoticeContext();
  const { errorContextErrorTranslator } = useErrorContext();

  // If a component wants to navigate to a different page it needs to call this function and pass the navigate function to it.
  // This checks to see if any editing is happening before allowing navigation.
  const Leave = (leave: () => void) => {
    if (editing > 0) {
      setLeaveFunc(() => () => {
        setEditing(0);
        leave();
      });
      setLeaveDialogOpen(true);
      return;
    }
    leave();
  };

  useEffect(() => {
    setLoadingMyPerson(true);
    const timeOutDeb = setTimeout(async () => {
      try {
        setMyPerson(new Person(personToPast(await retrievePerson())));
        setLoadingMyPerson(false);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving my person: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error retrieving my person: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        setLoadingMyPerson(false);
      }
    }, 0);
    return () => {
      setLoadingMyPerson(false);
      clearTimeout(timeOutDeb);
    };
  }, []);

  const updateMyPerson = async (values: Partial<Person>) => {
    setLoading(true);
    try {
      await updatePerson(
        personToFuture({
          ...myPerson,
          ...values,
        }),
        [AllowedPersonUpdatePaths.FIRST_NAME.toString()],
      );
      setMyPerson({
        ...myPerson,
        ...values,
      });
      enqueueSnackbar("Updated", { variant: "success" });
      setLoading(false);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error updating info: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `error updating info: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
      setLoading(false);
    }
  };

  // updates person once it loads
  useEffect(() => {
    if (!loading) {
      setNewPerson(myPerson);
    }
  }, [loading, myPerson]);

  return (
    <>
      <Dialog
        {...props}
        fullScreen
        PaperProps={{
          sx: {
            padding: 0,
            margin: 0,
          },
        }}
      >
        <Helmet>
          <title>Mesh | Profile</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="View your user profile, review your information and update your contact details"
          />
        </Helmet>
        {Banner}
        <DialogTitle
          sx={{
            dislay: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItemes: "center" }}>
            <Box
              sx={{
                width: "40px",
                height: "40px",
              }}
              component="img"
              src={meshLogoNoWords}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  mx: theme.spacing(2),
                  py: 0,
                  fontSize: "16px",
                  color: theme.palette.text.primary,
                }}
              >
                My Mesh Profile
              </Typography>
            </Box>
          </Box>
          <IconButton
            onClick={() =>
              Leave(() => {
                navigate(-1);
              })
            }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {isMobile && (
          <DialogTitle sx={{ p: 0 }}>
            <TopBar leave={Leave} />
          </DialogTitle>
        )}
        <DialogContent
          sx={{
            overflowY: "auto",
            [theme.breakpoints.up("sm")]: {
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
            },
            [theme.breakpoints.down("sm")]: {
              overflowX: "hidden",
              padding: 0,
            },
          }}
          className="meshScroll"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                [theme.breakpoints.down("lg")]: {
                  maxWidth: "606px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              }}
            >
              {!isMobile && <TopBar leave={Leave} />}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    lg: "row-reverse",
                  },
                  alignItems: {
                    sm: "center",
                    lg: "flex-start",
                  },
                  justifyContent: {
                    lg: "center",
                    sm: "flex-start",
                  },
                  [theme.breakpoints.down("lg")]: {
                    maxWidth: "606px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <KYCStatusCard
                  // disable the view button for company type clients
                  disableViewButton={
                    myClient?.clientKind === ClientKind.CompanyType
                  }
                  leave={Leave}
                  loading={loadingMyPerson}
                />
                <ProfileCard
                  setEditing={setEditing}
                  editValue={editing}
                  person={myPerson}
                  setPerson={setNewPerson}
                  loadingMyPerson={loadingMyPerson}
                  updateFunc={updateMyPerson}
                  errors={errors}
                  setErrors={setErrors}
                  leave={Leave}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        {isMobile && editing > 0 && (
          <DialogTitle
            sx={
              editing > 0 && isMobile
                ? {
                    p: theme.spacing(3, 3, 5, 3),
                    boxShadow: "0 24px 28px 12px #000",
                  }
                : {
                    height: 0,
                  }
            }
          >
            <Collapse in={isMobile && editing > 0}>
              <Button
                variant="outlined"
                fullWidth
                disabled={hasError(errors)}
                sx={{ height: "48px" }}
                onClick={async () => {
                  await updateMyPerson(newPerson ?? myPerson);
                }}
              >
                save
              </Button>
            </Collapse>
          </DialogTitle>
        )}
      </Dialog>
      <LeaveDialog
        open={leaveDialogOpen}
        leaveFunc={leaveFunc}
        closeDialog={() => setLeaveDialogOpen(false)}
        onClose={() => setLeaveDialogOpen(false)}
        errors={errors}
        updateFunc={async () => {
          await updateMyPerson(newPerson ?? myPerson);
        }}
      />
    </>
  );
};

export const hasError = (
  errors: Record<string, string | undefined>,
): boolean => {
  for (const key in errors) {
    if (errors[key] !== "") {
      return true;
    }
  }
  return false;
};
