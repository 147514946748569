"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.identificationTypeToString = identificationTypeToString;
exports.sourceOfWealthToString = sourceOfWealthToString;
exports.stringToIdentificationType = stringToIdentificationType;
exports.stringToSourceOfWealthType = stringToSourceOfWealthType;
const person_pb_1 = require("./person_pb");
const identificationTypeToStringMapping = {
    [person_pb_1.IdentificationType.PASSPORT_IDENTIFICATION_TYPE]: "Passport",
    [person_pb_1.IdentificationType.SOUTH_AFRICAN_ID_IDENTIFICATION_TYPE]: "South African ID",
};
class UnsupportedIdentificationTypeError extends Error {
    constructor(identificationTypes) {
        const message = `Unsupported IdentificationType: ${identificationTypes}`;
        super(message);
        this.identificationType = identificationTypes;
    }
}
function identificationTypeToString(identificationType) {
    if (identificationType in identificationTypeToStringMapping) {
        return identificationTypeToStringMapping[identificationType];
    }
    else {
        throw new UnsupportedIdentificationTypeError(identificationType);
    }
}
const sourceOfWealthToStringMapping = {
    [person_pb_1.SourceOfIncomeAndWealthType.ALLOWANCES]: "Allowances",
    [person_pb_1.SourceOfIncomeAndWealthType.BURSARY]: "Bursary",
    [person_pb_1.SourceOfIncomeAndWealthType.COURT_ORDER]: "Court Order",
    [person_pb_1.SourceOfIncomeAndWealthType.LOAN_FINANCIAL_INSTITUTION]: "Loan (Financial Institution)",
    [person_pb_1.SourceOfIncomeAndWealthType.LOAN_OTHER]: "Loan (Other)",
    [person_pb_1.SourceOfIncomeAndWealthType.MAINTENANCE]: "Maintenance",
    [person_pb_1.SourceOfIncomeAndWealthType.MATURING_INVESTMENT]: "Maturing Investments",
    [person_pb_1.SourceOfIncomeAndWealthType.PENSION]: "Pension",
    [person_pb_1.SourceOfIncomeAndWealthType.RENTAL_INCOME]: "Rental Income",
    [person_pb_1.SourceOfIncomeAndWealthType.COMPANY_PROFITS]: "Company Profits",
    [person_pb_1.SourceOfIncomeAndWealthType.COMPANY_SALE]: "Company Sale",
    [person_pb_1.SourceOfIncomeAndWealthType.DECEASED_STATE]: "Deceased Estate",
    [person_pb_1.SourceOfIncomeAndWealthType.DIVORCE_SETTLEMENT]: "Divorce Settlement",
    [person_pb_1.SourceOfIncomeAndWealthType.GIFT_OR_DONATION]: "Gift / Donation",
    [person_pb_1.SourceOfIncomeAndWealthType.INCOME_FROM_EMPLOYMENT]: "Income from Employment",
    [person_pb_1.SourceOfIncomeAndWealthType.INCOME_FROM_PREVIOUS_EMPLOYMENT]: "Income from Previous Employment",
    [person_pb_1.SourceOfIncomeAndWealthType.INHERITANCE]: "Inheritance",
    [person_pb_1.SourceOfIncomeAndWealthType.LOTTERY_WINNINGS_OR_GAMBLING]: "Lottery / Winnings / Gambling",
    [person_pb_1.SourceOfIncomeAndWealthType.SALE_OF_ASSET]: "Sale of Asset",
    [person_pb_1.SourceOfIncomeAndWealthType.SALE_OF_SHARES]: "Sale of Shares",
    [person_pb_1.SourceOfIncomeAndWealthType.SAVINGS_INVESTMENT_OR_DIVIDEND]: "Savings  / Investment / Dividend",
    [person_pb_1.SourceOfIncomeAndWealthType.TRUST_DISTRIBUTIONS]: "Trust Distributions",
};
class UnsupportedSourceOfIncomeAndWealthTypeError extends Error {
    constructor(sourceOfWealth) {
        const message = `Unsupported SourceOfIncomeAndWealthType: ${sourceOfWealth}`;
        super(message);
        this.sourceOfWealth = sourceOfWealth;
    }
}
function sourceOfWealthToString(sourceOfWealth) {
    if (sourceOfWealth in sourceOfWealthToStringMapping) {
        return sourceOfWealthToStringMapping[sourceOfWealth];
    }
    else {
        throw new UnsupportedSourceOfIncomeAndWealthTypeError(sourceOfWealth);
    }
}
const stringToIdentificationTypeMapping = {
    Passport: person_pb_1.IdentificationType.PASSPORT_IDENTIFICATION_TYPE,
    "South African ID": person_pb_1.IdentificationType.SOUTH_AFRICAN_ID_IDENTIFICATION_TYPE,
};
class UnsupportedIdentificationTypeStringError extends Error {
    constructor(identificationTypes) {
        const message = `Unsupported String IdentificationType: ${identificationTypes}`;
        super(message);
        this.identificationType = identificationTypes;
    }
}
function stringToIdentificationType(identificationType) {
    if (identificationType in stringToIdentificationTypeMapping) {
        return stringToIdentificationTypeMapping[identificationType];
    }
    else {
        throw new UnsupportedIdentificationTypeStringError(identificationType);
    }
}
const stringToSourceOfWealthTypeMapping = {
    Allowances: person_pb_1.SourceOfIncomeAndWealthType.ALLOWANCES,
    Bursary: person_pb_1.SourceOfIncomeAndWealthType.BURSARY,
    "Court Order": person_pb_1.SourceOfIncomeAndWealthType.COURT_ORDER,
    "Loan (Financial Institution)": person_pb_1.SourceOfIncomeAndWealthType.LOAN_FINANCIAL_INSTITUTION,
    "Loan (Other)": person_pb_1.SourceOfIncomeAndWealthType.LOAN_OTHER,
    Maintenance: person_pb_1.SourceOfIncomeAndWealthType.MAINTENANCE,
    "Maturing Investments": person_pb_1.SourceOfIncomeAndWealthType.MATURING_INVESTMENT,
    Pension: person_pb_1.SourceOfIncomeAndWealthType.PENSION,
    "Rental Income": person_pb_1.SourceOfIncomeAndWealthType.RENTAL_INCOME,
    "Company Profits": person_pb_1.SourceOfIncomeAndWealthType.COMPANY_PROFITS,
    "Company Sale": person_pb_1.SourceOfIncomeAndWealthType.COMPANY_SALE,
    "Deceased Estate": person_pb_1.SourceOfIncomeAndWealthType.DECEASED_STATE,
    "Divorce Settlement": person_pb_1.SourceOfIncomeAndWealthType.DIVORCE_SETTLEMENT,
    "Gift / Donation": person_pb_1.SourceOfIncomeAndWealthType.GIFT_OR_DONATION,
    "Income from Employment": person_pb_1.SourceOfIncomeAndWealthType.INCOME_FROM_EMPLOYMENT,
    "Income from Previous Employment": person_pb_1.SourceOfIncomeAndWealthType.INCOME_FROM_PREVIOUS_EMPLOYMENT,
    Inheritance: person_pb_1.SourceOfIncomeAndWealthType.INHERITANCE,
    "Lottery / Winnings / Gambling": person_pb_1.SourceOfIncomeAndWealthType.LOTTERY_WINNINGS_OR_GAMBLING,
    "Sale of Asset": person_pb_1.SourceOfIncomeAndWealthType.SALE_OF_ASSET,
    "Sale of Shares": person_pb_1.SourceOfIncomeAndWealthType.SALE_OF_SHARES,
    "Savings  / Investment / Dividend": person_pb_1.SourceOfIncomeAndWealthType.SAVINGS_INVESTMENT_OR_DIVIDEND,
    "Trust Distributions": person_pb_1.SourceOfIncomeAndWealthType.TRUST_DISTRIBUTIONS,
};
class UnsupportedSourceOfIncomeAndWealthTypeStringError extends Error {
    constructor(sourceOfWealth) {
        const message = `Unsupported String SourceOfIncomeAndWealthType: ${sourceOfWealth}`;
        super(message);
        this.sourceOfWealth = sourceOfWealth;
    }
}
function stringToSourceOfWealthType(sourceOfWealth) {
    if (sourceOfWealth in stringToSourceOfWealthTypeMapping) {
        return stringToSourceOfWealthTypeMapping[sourceOfWealth];
    }
    else {
        throw new UnsupportedSourceOfIncomeAndWealthTypeStringError(sourceOfWealth);
    }
}
