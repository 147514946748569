import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";

export const EmailTOTPSenderServiceProviderName =
  "verification-EmailTOTPSender";

export interface SendEmailTOTPRequest {
  firebaseToken: string;
}

export interface SendEmailTOTPResponse {
  expTime: string;
  expPeriod: string;
}

export const EmailTOTPSender = {
  async SendEmailTOTP(
    request: SendEmailTOTPRequest,
  ): Promise<SendEmailTOTPResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicRatedLimitedURL"),
      method: `${EmailTOTPSenderServiceProviderName}.SendEmailTOTP`,
      request,
    });
    return {
      expTime: response.expTime,
      expPeriod: response.expPeriod,
    };
  },
};
