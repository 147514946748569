import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Collapse,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EditableInfo } from "views/UserProfileDialog/util";
import { Person, PersonContactDetails } from "james/legal/person";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataValidationFunc,
  formUpdater,
  FormUpdaterType,
} from "./contactDetailsUseValidatedForm";
import isEqual from "lodash/isEqual";
import { MobileNumberVerification } from "components/MobileNumberVerification/MobileNumberVerification";
import config from "react-global-configuration";
import { usePersonContext } from "context/Person/PersonService";

interface ContactDetailsProps {
  initialPerson: Person;
  loadingMyPerson: boolean;
  setGlobalEditing: React.Dispatch<React.SetStateAction<number>>;
  editValue: number;
  updateFunc: (info: Partial<Person>) => void;
  setPerson: React.Dispatch<React.SetStateAction<Person | undefined>>;
  errors: Record<string, string | undefined>;
  setErrors: (error: Record<string, string | undefined>) => void;
}

export const ContactDetails = ({
  initialPerson,
  loadingMyPerson,
  setGlobalEditing,
  editValue,
  updateFunc,
  setPerson,
  setErrors,
}: ContactDetailsProps): JSX.Element => {
  const { myPerson } = usePersonContext();
  const [editMode, setEditMode] = useState<boolean>(false);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [verifyMobileNumber, setVerifyMobileNumber] = useState(false);
  const [verifiedMobileNumber, setVerifiedNumber] = useState(false);

  const [contactState, validationResults, updateForm, validationInProgress] =
    useValidatedForm<PersonContactDetails, FormUpdaterType>(
      formDataValidationFunc,
      async () => new PersonContactDetails(),
      formUpdater,
      new PersonContactDetails(),
      new Set<string>([]),
      { skipTouchedFieldsOnValidation: true },
    );

  // keep errors in form aligned with errors in parent component
  useEffect(() => {
    setErrors(
      validationResults.fieldValidations as {
        [key: string]: string | undefined;
      },
    );
  }, [validationResults.fieldValidations]);

  // keep initial person in parent component aligned with form state
  useEffect(() => {
    setPerson(
      new Person({
        ...initialPerson,
        contactDetails: new PersonContactDetails(contactState),
      }),
    );
  }, [contactState]);

  useEffect(() => {
    if (loadingMyPerson) {
      return;
    }
    updateForm.cellphoneNumber(initialPerson.contactDetails.cellphoneNumber);
    updateForm.telephoneNumber(initialPerson.contactDetails.telephoneNumber);
    updateForm.emailAddress(initialPerson.contactDetails.emailAddress);
  }, [loadingMyPerson, initialPerson]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SkeletonLoader loading={loadingMyPerson}>
          <Typography sx={{ fontWeight: "bold" }}>Contact Details</Typography>
        </SkeletonLoader>
      </Box>
      <Box
        sx={{
          display: "grid",
          mb: theme.spacing(1),
        }}
      >
        {config.get("allowMFAVerification") && (
          <Card
            sx={(theme) => ({
              margin: theme.spacing(3, 0, 2, 0),
              height: theme.spacing(7),
              padding: theme.spacing(0, 2, 0, 3),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <EditableInfo
              id="ContactDetails-Mobile"
              loading={loadingMyPerson}
              disabled={loading}
              fullWidth
              label="Mobile Number"
              value={
                myPerson.getContactdetails()
                  ? myPerson
                      ?.getContactdetails()
                      ?.getCellphonenumber()
                      ?.getValue()
                      .toString().length === 4 &&
                    myPerson
                      ?.getContactdetails()
                      ?.getCellphonenumber()
                      ?.getValue()
                      .toString()
                      .charAt(0) === "•"
                    ? " "
                    : myPerson
                        ?.getContactdetails()
                        ?.getCellphonenumber()
                        ?.getValue()
                  : myPerson
                      ?.getContactdetails()
                      ?.getCellphonenumber()
                      ?.getValue()
              }
              editing={editMode}
              FormHelperTextProps={{
                sx: {
                  color: theme.palette.error.main,
                  my: 0,
                },
              }}
              helperText={validationResults.fieldValidations.cellphoneNumber}
            />
            {contactState.cellphoneNumber.verified ? (
              <Chip
                color="success"
                variant="filled"
                sx={{ height: "24px", width: "90px" }}
                label="Verified"
              />
            ) : verifiedMobileNumber ? (
              <Chip
                color="success"
                variant="filled"
                sx={{ height: "24px", width: "90px" }}
                label="Verified"
              />
            ) : (
              <Button
                color="primary"
                variant="contained"
                sx={{ height: "30px" }}
                onClick={() => setVerifyMobileNumber(true)}
              >
                verify mobile
              </Button>
            )}
          </Card>
        )}
        <Card
          sx={(theme) => ({
            height: theme.spacing(7),
            padding: theme.spacing(0, 2, 0, 3),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <EditableInfo
            id="ContactDetails-Email"
            loading={loadingMyPerson}
            disabled={loading}
            fullWidth
            label="Email"
            value={contactState.emailAddress}
          />
          <Chip
            color="success"
            variant="filled"
            sx={{ height: "24px", width: "90px" }}
            label="Verified"
          />
        </Card>
      </Box>
      <Collapse in={editMode && !isSmDown}>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Button
            id="ContactDetails-Save-Button"
            fullWidth={isSmDown}
            variant="outlined"
            children="Save"
            size="large"
            disabled={
              loading || !validationResults.valid || validationInProgress
            }
            sx={{
              height: {
                xs: "48px",
                sm: "36px",
              },
            }}
            onClick={async () => {
              setLoading(true);
              if (!isEqual(contactState, initialPerson.contactDetails)) {
                updateFunc({
                  contactDetails: contactState,
                });
              }
              setEditMode(false);
              setGlobalEditing(editValue - 1);
              setLoading(false);
            }}
          />
          {loading && (
            <CircularProgress sx={(theme) => ({ mx: theme.spacing(2) })} />
          )}
        </Box>
      </Collapse>
      {verifyMobileNumber && config.get("allowMFAVerification") && (
        <MobileNumberVerification
          renderDialog={true}
          firstTimeLogin={false}
          closeDialog={setVerifyMobileNumber}
          setVerifiedNumber={setVerifiedNumber}
        />
      )}
    </>
  );
};
