import { AuditEntry, AuditEntryActionType } from "james/audit";
import { PersonContactDetails } from "james/legal/person";
import { Address } from "james/location";
import {
  Person as FuturePerson,
  Address as FutureAddress,
  ContactDetails,
  CellphoneNumber,
} from "@mesh/common-js/dist/legal/person_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { auditEntryActionToPast } from "@mesh/common-js/dist/audit/entry";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  identificationTypeToString,
  sourceOfWealthToString,
  stringToIdentificationType,
  stringToSourceOfWealthType,
} from "@mesh/common-js/dist/legal/person";
import { auditEntryToFuture } from "james/audit/Entry";

export class Person {
  public id = "";
  public auditEntry: AuditEntry = new AuditEntry();
  public firstName = "";
  public middleNames = "";
  public lastName = "";
  public dateOfBirth = "";
  public nationality = "";
  public influentialPerson = "";
  public identificationType = "";
  public identificationNumber = "";
  public identificationNumberExpiry = "";
  public contactDetails: PersonContactDetails = new PersonContactDetails();
  public residentialAddress: Address = new Address();
  public sourceOfWealth: string = "";
  public sourceOfIncome: string = "";

  constructor(person?: Person) {
    if (!person) {
      return;
    }

    this.id = person.id;
    this.auditEntry = new AuditEntry(person.auditEntry);
    this.firstName = person.firstName;
    this.middleNames = person.middleNames;
    this.lastName = person.lastName;
    this.nationality = person.nationality;
    this.identificationType = person.identificationType;
    this.identificationNumber = person.identificationNumber;
    this.identificationNumberExpiry = person.identificationNumberExpiry;
    this.contactDetails = new PersonContactDetails(person.contactDetails);
    this.dateOfBirth = person.dateOfBirth;
    this.residentialAddress = new Address(person.residentialAddress);
    this.sourceOfWealth = person.sourceOfWealth;
    this.sourceOfIncome = person.sourceOfIncome;
    this.influentialPerson = person.influentialPerson;
  }
}

export enum InfluentialPersonStatus {
  InfluentialPersonStatus = "Yes",
  NotInfluentialPersonStatus = "No",
}

export const personToFuture = (person: Person) => {
  return new FuturePerson()
    .setId(person.id)
    .setAuditentry(auditEntryToFuture(person.auditEntry))
    .setFirstname(person.firstName)
    .setMiddlenames(person.middleNames)
    .setLastname(person.lastName)
    .setDateofbirth(person.dateOfBirth)
    .setNationality(person.nationality)
    .setInfluentialperson(person.influentialPerson)
    .setIdentificationtype(
      stringToIdentificationType(person.identificationType),
    )
    .setIdentificationnumber(person.identificationNumber)
    .setIdentificationnumberexpiry(person.identificationNumberExpiry)
    .setContactdetails(
      new ContactDetails()
        .setEmailaddress(person.contactDetails.emailAddress)
        .setTelephonenumber(person.contactDetails.telephoneNumber)
        .setCellphonenumber(
          new CellphoneNumber()
            .setValue(person.contactDetails.cellphoneNumber.value)
            .setVerified(
              person.contactDetails.cellphoneNumber.verified
                ? person.contactDetails.cellphoneNumber.verified
                : false,
            ),
        ),
    )
    .setResidentialaddress(
      new FutureAddress()
        .setCity(person.residentialAddress.city)
        .setAddressline1(person.residentialAddress.addressLine1)
        .setAddressline2(person.residentialAddress.addressLine2)
        .setSuburb(person.residentialAddress.suburb)
        .setProvince(person.residentialAddress.province)
        .setPostalcode(person.residentialAddress.postalCode)
        .setCountrycode(person.residentialAddress.countryCode),
    )
    .setSourceofwealth(stringToSourceOfWealthType(person.sourceOfWealth))
    .setSourceofincome(stringToSourceOfWealthType(person.sourceOfIncome));
};

export const personToPast = (person: FuturePerson) => {
  return new Person({
    id: person.getId(),
    auditEntry: new AuditEntry({
      userID: person?.getAuditentry()?.getUserid() ?? "",
      time: protobufTimestampToDayjs(
        person?.getAuditentry()?.getTime() ?? new Timestamp(),
      ).format(),
      action: auditEntryActionToPast(
        person?.getAuditentry()?.getAction(),
      ) as AuditEntryActionType,
      version: person?.getAuditentry()?.getVersion() ?? 0,
    }),
    firstName: person.getFirstname(),
    middleNames: person.getMiddlenames(),
    lastName: person.getLastname(),
    nationality: person.getNationality(),
    identificationType: identificationTypeToString(
      person.getIdentificationtype(),
    ),

    identificationNumber: person.getIdentificationnumber(),
    identificationNumberExpiry: person.getIdentificationnumberexpiry(),

    contactDetails: new PersonContactDetails({
      emailAddress: person?.getContactdetails()?.getEmailaddress() ?? "",
      telephoneNumber: person?.getContactdetails()?.getTelephonenumber() ?? "",
      cellphoneNumber: {
        value:
          person?.getContactdetails()?.getCellphonenumber()?.getValue() ?? "",
        verified: person
          ?.getContactdetails()
          ?.getCellphonenumber()
          ?.getVerified(),
      },
    }),

    dateOfBirth: person.getDateofbirth(),

    residentialAddress: new Address({
      addressLine1: person?.getResidentialaddress()?.getAddressline1() ?? "",
      addressLine2: person?.getResidentialaddress()?.getAddressline2() ?? "",
      suburb: person?.getResidentialaddress()?.getSuburb() ?? "",
      city: person?.getResidentialaddress()?.getCity() ?? "",
      province: person?.getResidentialaddress()?.getProvince() ?? "",
      countryCode: person?.getResidentialaddress()?.getCountrycode() ?? "",
      postalCode: person?.getResidentialaddress()?.getPostalcode() ?? "",
    }),

    sourceOfWealth: sourceOfWealthToString(person.getSourceofwealth()),
    sourceOfIncome: sourceOfWealthToString(person.getSourceofincome()),
    influentialPerson: person.getInfluentialperson(),
  });
};

export const AllPEPDeclarationStatuses: InfluentialPersonStatus[] = [
  InfluentialPersonStatus.InfluentialPersonStatus,
  InfluentialPersonStatus.NotInfluentialPersonStatus,
];
