import * as Sentry from "@sentry/react";
import { decodeError } from "./decodeError";
import {
  JSONRPCCallAbortedError,
  JSONRPCContactFailedError,
  JSONRPCHTTPError,
  JSONRPCMethodError,
  JSONRPCTooManyRequestsError,
  JSONRPCUnauthorizedError,
  JSONRPCUnexpectedError,
} from "./errors";
import { ErrAccessTokenValueIsEmptyErrorCode } from "james/security/authentication/AccessTokenErrors";
import { logoutUser } from "context/Application/Application";
import config from "react-global-configuration";
import { Environment } from "const";

export type JSONRPCRequestProps = {
  url: string;
  method: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: any;
};

export type JSONRPCRequestOpts = {
  verbose?: boolean;
  additionalHeaders?: Headers;
  excludeCredentials?: boolean;
  signal?: AbortSignal;
};

export async function jsonRPCRequest(
  { url, method, request }: JSONRPCRequestProps,
  opts?: JSONRPCRequestOpts,
) {
  const header = new Headers({
    "Content-Type": "application/json",
  });

  // add any additional headers
  if (opts?.additionalHeaders) {
    opts.additionalHeaders.forEach((value: string, name: string) =>
      header.append(name, value),
    );
  }

  // SHOULD ONLY BE ENABLED FOR LOCAL DEVELOPMENT!
  const environment = config.get("environment") as Environment;
  if (environment === Environment.Local) {
    const currentTime = config.get("currentTime");
    header.append("mesh-simulated-time", currentTime);
    console.debug("Set mesh-simulated-time to ", currentTime);
  }

  const body = {
    jsonrpc: "2.0",
    method,
    params: request,
    id: 1234,
  };
  console.debug(`API Request: ${body.method} -->\n`, body.params);
  if (opts?.verbose) {
    try {
      console.debug("\n", JSON.parse(JSON.stringify(body.params)));
      console.debug("\n", JSON.stringify(body.params));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      Sentry.captureException(e);
      console.error("error parsing stringified body.params[0]");
      throw e;
    }
  }

  // perform request
  let responseObject;
  try {
    responseObject = await fetch(url, {
      method: "POST",
      headers: header,
      mode: "cors",
      body: JSON.stringify(body),
      credentials: opts?.excludeCredentials ? undefined : "include",
      signal: opts?.signal,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // do not log errors for abort errors
    if (e.name === "AbortError") {
      const err = new JSONRPCCallAbortedError(body.method);
      console.debug(err.message);
      throw decodeError(err);
    }

    // prepare specific error
    const err = new JSONRPCContactFailedError(e, body.method);

    // notify of error with sentry
    Sentry.captureException(err, {
      extra: {
        request: body,
      },
    });

    // log error
    console.error(err.message);

    // rethrow specific error for caller to handle
    throw decodeError(err);
  }

  // check for unauthorised HTTP response
  if (responseObject.status === 401) {
    // prepare specific error
    const err = new JSONRPCUnauthorizedError(body.method);

    // notify of error using sentry
    Sentry.captureException(err, {
      extra: {
        request: body,
        response: responseObject,
      },
    });

    // log error
    console.error(err.message);

    // rethrow specific error for caller to handle
    throw decodeError(err);
  } else if (responseObject.status === 429) {
    // prepare specific error
    const err = new JSONRPCTooManyRequestsError(
      body.method,
      responseObject.status,
    );

    // notify of error using sentry
    Sentry.captureException(err, {
      extra: {
        request: body,
        response: responseObject,
      },
    });

    // log error
    console.error(err.message);

    // rethrow specific error for caller to handle
    throw decodeError(err);
  } else if (responseObject.status !== 200) {
    // prepare specific error
    const err = new JSONRPCHTTPError(body.method, responseObject.status);

    // notify of error using sentry
    Sentry.captureException(err, {
      extra: {
        request: body,
        response: responseObject,
      },
    });

    // log error
    console.error(err.message);

    // rethrow specific error for caller to handle
    throw decodeError(err);
  }

  // try and parse response object as JSON
  let responseObjectJson;
  try {
    responseObjectJson = await responseObject.json();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // do not log errors for abort errors
    if (e.name === "AbortError") {
      const err = new JSONRPCCallAbortedError(body.method);
      console.debug(err.message);
      throw decodeError(err);
    }
    // parsing response object as JSON has failed

    // prepare specific error
    let err = new JSONRPCUnexpectedError(
      body.method,
      `failed to parse response object JSON: ${e}`,
    );
    console.error(err.message);

    // try get response object body text
    let responseBody = "";
    try {
      responseBody = await responseObject.text();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      err = new JSONRPCUnexpectedError(
        body.method,
        `error reading response body: ${e}`,
      );
      console.error(err.message);
    }

    // notify of error using sentry
    Sentry.captureException(err, {
      extra: {
        request: body,
        response: responseBody,
      },
    });

    // rethrow specific error for caller to handle
    throw decodeError(err);
  }
  // parsing response object as JSON has succeeded

  // if the response object has a result field then the request was successful
  // (i.e. no error was returned from JSON-RPC adaptor)
  if (responseObjectJson.result) {
    console.debug(
      `API Response Success: ${body.method}-->\n`,
      responseObjectJson.result,
    );
    return responseObjectJson.result;
  }
  // otherwise an error occurred
  // (i.e. an error was returned from JSON-RPC adaptor)

  // prepare specific error
  const err = new JSONRPCMethodError(body.method, responseObjectJson.error);

  // check the error for ErrAccessTokenValueIsEmpty code
  if (err.rpcError.code === ErrAccessTokenValueIsEmptyErrorCode) {
    // logout the user
    await logoutUser();
    return;
  }

  // notify of error using sentry
  Sentry.captureException(err, {
    extra: {
      request: body,
      response: responseObjectJson,
    },
  });

  // log error
  console.error(err.message);

  // rethrow specific error for caller to handle
  throw decodeError(err);
}
