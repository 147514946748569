import React, { useState } from "react";
import { Router } from "routes/Router";
import { Fee } from "./components/Fee";
import { RouteType } from "routes/Route";
import { IssuerFees } from "./components/IssuerFees";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";

export const Fees = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { viewConfiguration } = useApplicationContext();
  const [selectedTab, setSelectedTab] = useState(() => {
    if (location.pathname.includes("issuer-fees")) return 1;
    if (location.pathname.includes("fees")) return 0;
  });

  let feesRoutes: RouteType[] = [
    {
      name: "fees",
      id: "fees-table",
      path: "/market-management/fees",
      component: <Fee />,
    },
  ];

  if (viewConfiguration?.Fees?.["Issuer Fees"]) {
    feesRoutes = [
      ...feesRoutes,
      {
        name: "fees",
        id: "issuer-fees",
        path: "/market-management/fees/issuer-fees",
        component: <IssuerFees />,
      },
    ];
  }

  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.cardInner,
        })}
      >
        <Tabs value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
          <Tab
            title="Fees"
            onClick={() => navigate("")}
            label="Fees"
            value={0}
          />
          {viewConfiguration?.Fees?.["Issuer Fees"] && (
            <Tab
              title="Issuer Fees"
              onClick={() => navigate("issuer-fees")}
              label="Issuer Fees"
              value={1}
            />
          )}
        </Tabs>
      </Box>
      <Router baseURL={"/market-management/fees"} routes={feesRoutes} />
    </Box>
  );
};
