import { AuditEntryActionType } from "./ActionType";
import dayjs from "dayjs";
import {
  Action,
  Entry as FutureEntry,
} from "@mesh/common-js/dist/audit/entry_pb";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters/time";

export class Entry {
  public userID = "";

  public action: AuditEntryActionType =
    AuditEntryActionType.CREATEDAuditActionType;

  public time: string = dayjs().format();

  public version = 0;

  constructor(entry?: Entry) {
    if (!entry) {
      return;
    }
    this.userID = entry.userID;
    this.action = entry.action;
    this.time = entry.time;
    this.version = entry.version;
  }
}

export const auditEntryToFuture = (entry: Entry) => {
  return new FutureEntry()
    .setUserid(entry.userID)
    .setTime(dayjsToProtobufTimestamp(dayjs(entry.time)))
    .setAction(auditEntryActionToFuture(entry.action as AuditEntryActionType))
    .setVersion(entry.version);
};

const auditEntryToStringMapping: {
  [key in Action]: AuditEntryActionType;
} = {
  [Action.CREATED_ACTION]: AuditEntryActionType.CREATEDAuditActionType,
  [Action.MODIFIED_ACTION]: AuditEntryActionType.MODIFIEDAuditActionType,
  [Action.DELETED_ACTION]: AuditEntryActionType.DELETEDAuditActionType,
  [Action.RESTORED_ACTION]: AuditEntryActionType.RESTOREDAuditActionType,
  [Action.UNDEFINED_ACTION]: AuditEntryActionType.UndefinedActionType,
};

class UnsupportedAuditEntryActionTypeError extends Error {
  action: Action;

  constructor(action: Action) {
    const message = `Unsupported Action Type: ${action}`;
    super(message);
    this.action = action;
  }
}

export function auditEntryActionToPast(action?: Action): AuditEntryActionType {
  if (!action) {
    return auditEntryToStringMapping[Action.UNDEFINED_ACTION];
  }
  if (action in auditEntryToStringMapping) {
    return auditEntryToStringMapping[action];
  } else {
    throw new UnsupportedAuditEntryActionTypeError(action);
  }
}

const pastEntryActionToFutureEntryActionMapping: {
  [key in AuditEntryActionType]: Action;
} = {
  [AuditEntryActionType.CREATEDAuditActionType]: Action.CREATED_ACTION,
  [AuditEntryActionType.MODIFIEDAuditActionType]: Action.MODIFIED_ACTION,
  [AuditEntryActionType.DELETEDAuditActionType]: Action.DELETED_ACTION,
  [AuditEntryActionType.RESTOREDAuditActionType]: Action.RESTORED_ACTION,
  [AuditEntryActionType.UndefinedActionType]: Action.UNDEFINED_ACTION,
};

class UnsupportedEntryActionTypeError extends Error {
  action: AuditEntryActionType;

  constructor(action: AuditEntryActionType) {
    const message = `Unsupported Action Type: ${action}`;
    super(message);
    this.action = action;
  }
}

export function auditEntryActionToFuture(action: AuditEntryActionType): Action {
  if (action in pastEntryActionToFutureEntryActionMapping) {
    return pastEntryActionToFutureEntryActionMapping[action];
  } else {
    throw new UnsupportedEntryActionTypeError(action);
  }
}
