export class JSONRPCCallAbortedError extends Error {
  static ErrorCode = 100201;

  /** error type */
  public name = "JSON-RPC Call Aborted Error";

  /** error message */
  public message = "";

  /** rpc method that failed */
  public method = "";

  constructor(method: string) {
    super();
    this.message = `call for rpc method '${method}' was aborted`;
    this.method = method;
  }
}

export class JSONRPCContactFailedError extends Error {
  static ErrorCode = 100202;

  /** error type */
  public name = "JSON-RPC Contact Failed Error";

  /** error message */
  public message = "";

  /**
   * the error returned when
   * contacting the server failed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any;

  /** rpc method that failed */
  public method = "";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(error: any, method: string) {
    super();
    this.method = method;
    this.error = error;
    this.message = `server contact failed for rpc method '${method}' with error '${error}'`;
  }
}

export class JSONRPCHTTPError extends Error {
  static ErrorCode = 100203;

  /** error type */
  public name = "JSON-RPC HTTP Error";

  /** error message */
  public message = "";

  /** rpc method that failed */
  public method = "";

  /** http error code */
  public code = 0;

  constructor(method: string, code: number) {
    super();
    this.message = `rpc method '${method}' failed with HTTP code ${code}`;
    this.method = method;
    this.code = code;
  }
}

export class JSONRPCUnauthorizedError extends Error {
  static ErrorCode = 100204;

  /** error type */
  public name = "JSON-RPC Unauthorized Error";

  /** error message */
  public message = "";

  /** rpc method that failed */
  public method = "";

  constructor(method: string) {
    super();
    this.message = `unauthorized for rpc method ${method}`;
    this.method = method;
  }
}

export class JSONRPCMethodError extends Error {
  /** error type */
  public name = "JSON-RPC Method Error";

  /** error message */
  public message = "";

  /** rpc method that failed */
  public method = "";

  /** rpc error message */
  public rpcError: { code: number; message: string } = {
    code: 0,
    message: "",
  };

  constructor(method: string, rpcError: { code: number; message: string }) {
    super();
    this.message = `rpc method '${method}' failed with error.message: '${rpcError?.message} & error.code: ${rpcError?.code}'`;
    this.method = method;
    this.rpcError = rpcError;
  }
}

export class JSONRPCUnexpectedError extends Error {
  static ErrorCode = 100205;

  /** error type */
  public name = "JSON-RPC Unexpected Error";

  /** error message */
  public message = "";

  /** rpc method that failed */
  public method = "";

  /** error */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(method: string, error: any) {
    super();
    this.message = `unexpected error performing rpc method '${method}' - got error ${error}`;
    this.method = method;
    this.error = error;
  }
}

export class JSONRPCTooManyRequestsError extends Error {
  static ErrorCode = 100206;

  /** error type */
  public name = "JSON-RPC Too Many Requests Error";

  /** error message */
  public message = "";

  /** rpc method that failed */
  public method = "";

  /** error */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(method: string, error: any) {
    super();
    this.message = `too many requests for rpc method '${method}' - got error ${error}`;
    this.method = method;
    this.error = error;
  }
}
