// source: iam/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var audit_entry_pb = require('../audit/entry_pb.js');
goog.object.extend(proto, audit_entry_pb);
var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol('proto.iam.Guide', null, global);
goog.exportSymbol('proto.iam.State', null, global);
goog.exportSymbol('proto.iam.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.iam.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.iam.User.repeatedFields_, null);
};
goog.inherits(proto.iam.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.iam.User.displayName = 'proto.iam.User';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.iam.User.repeatedFields_ = [6,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.iam.User.prototype.toObject = function(opt_includeInstance) {
  return proto.iam.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.iam.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iam.User.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
clientid: jspb.Message.getFieldWithDefault(msg, 3, ""),
personid: jspb.Message.getFieldWithDefault(msg, 4, ""),
email: jspb.Message.getFieldWithDefault(msg, 5, ""),
roleidsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
ausagreementversion: jspb.Message.getFieldWithDefault(msg, 7, ""),
auditentry: (f = msg.getAuditentry()) && audit_entry_pb.Entry.toObject(includeInstance, f),
state: jspb.Message.getFieldWithDefault(msg, 9, 0),
completedguidesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.iam.User}
 */
proto.iam.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.iam.User;
  return proto.iam.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.iam.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.iam.User}
 */
proto.iam.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoleids(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusagreementversion(value);
      break;
    case 8:
      var value = new audit_entry_pb.Entry;
      reader.readMessage(value,audit_entry_pb.Entry.deserializeBinaryFromReader);
      msg.setAuditentry(value);
      break;
    case 9:
      var value = /** @type {!proto.iam.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.iam.Guide>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompletedguides(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.iam.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.iam.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.iam.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.iam.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPersonid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRoleidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAusagreementversion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuditentry();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      audit_entry_pb.Entry.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCompletedguidesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.iam.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.iam.User.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string clientID = 3;
 * @return {string}
 */
proto.iam.User.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string personID = 4;
 * @return {string}
 */
proto.iam.User.prototype.getPersonid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setPersonid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.iam.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string roleIDs = 6;
 * @return {!Array<string>}
 */
proto.iam.User.prototype.getRoleidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setRoleidsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.addRoleids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.clearRoleidsList = function() {
  return this.setRoleidsList([]);
};


/**
 * optional string ausAgreementVersion = 7;
 * @return {string}
 */
proto.iam.User.prototype.getAusagreementversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setAusagreementversion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional audit.Entry auditEntry = 8;
 * @return {?proto.audit.Entry}
 */
proto.iam.User.prototype.getAuditentry = function() {
  return /** @type{?proto.audit.Entry} */ (
    jspb.Message.getWrapperField(this, audit_entry_pb.Entry, 8));
};


/**
 * @param {?proto.audit.Entry|undefined} value
 * @return {!proto.iam.User} returns this
*/
proto.iam.User.prototype.setAuditentry = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.clearAuditentry = function() {
  return this.setAuditentry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.iam.User.prototype.hasAuditentry = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional State state = 9;
 * @return {!proto.iam.State}
 */
proto.iam.User.prototype.getState = function() {
  return /** @type {!proto.iam.State} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.iam.State} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated Guide completedGuides = 10;
 * @return {!Array<!proto.iam.Guide>}
 */
proto.iam.User.prototype.getCompletedguidesList = function() {
  return /** @type {!Array<!proto.iam.Guide>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.iam.Guide>} value
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.setCompletedguidesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.iam.Guide} value
 * @param {number=} opt_index
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.addCompletedguides = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.iam.User} returns this
 */
proto.iam.User.prototype.clearCompletedguidesList = function() {
  return this.setCompletedguidesList([]);
};


/**
 * @enum {number}
 */
proto.iam.Guide = {
  FIRST_TIME_LOGIN: 0,
  PLATFORM_GUIDED_JOURNEY: 1
};

/**
 * @enum {number}
 */
proto.iam.State = {
  REGISTERED_STATE: 0,
  DISABLED_STATE: 1
};

goog.object.extend(exports, proto.iam);
