import React from "react";
import { useExchangeDashboardStore } from "../store";
import * as StellarSDK from "stellar-sdk";
import { useExchangeStore } from "../../../store";
import { useStellarContext } from "context/Stellar";
import { ServerConnectionStatus } from "pkgTemp/stellar/ClientServer";
import { OrderBook } from "pkgTemp/stellar/Orders";

export const useFetchAndStreamOrderBook = () => {
  const store = useExchangeDashboardStore();
  const { stellarOrdersViewer } = useStellarContext();
  const exchangeStore = useExchangeStore();
  const [record, setRecord] =
    React.useState<StellarSDK.Horizon.ServerApi.OrderbookRecord>();

  React.useEffect(() => {
    if (!record) return;
    handleNewRecord(record);
  }, [record]);

  const handleNewOrderBook = (orderBook: OrderBook) => {
    store.updateOrderBookState.serverConnectionStatus(
      ServerConnectionStatus.Connecting,
    );

    if (orderBook.asks.length > 0 && orderBook.bids.length > 0) {
      const spread = orderBook.asks[0].price
        .minus(orderBook.bids[orderBook.bids.length - 1].price)
        .abs();
      store.updateOrderBookState.spread(spread);
    }

    store.updateOrderBookState.serverConnectionStatus(
      ServerConnectionStatus.Connected,
    );

    store.updateOrderBookState.highestPrice(orderBook.getHighestOrder());
    store.updateOrderBookState.orderBookState(orderBook);
  };

  const handleNewRecord = (
    orderBook: StellarSDK.Horizon.ServerApi.OrderbookRecord,
  ) => {
    handleNewOrderBook(
      OrderBook.fromOrderBookRecord(
        orderBook,
        store.orderBookState.orderBookState.precision,
      ),
    );
  };

  // Fetch and stream order book
  const fetchAndStreamOrderBook = async () => {
    if (!exchangeStore.marketListingPair) return;
    const pair = exchangeStore.marketListingPair;

    let baseAsset = StellarSDK.Asset.native();
    let counterAsset = StellarSDK.Asset.native();

    if (
      pair.base.code !== baseAsset.code &&
      pair.base.issuer !== baseAsset.issuer
    ) {
      baseAsset = new StellarSDK.Asset(pair.base.code, pair.base.issuer);
    }
    if (
      pair.counter.code !== counterAsset.code &&
      pair.counter.issuer !== counterAsset.issuer
    ) {
      counterAsset = new StellarSDK.Asset(
        pair.counter.code,
        pair.counter.issuer,
      );
    }
    const onError = () => {
      store.updateOrderBookState.serverConnectionStatus(
        ServerConnectionStatus.Disconnected,
      );
    };

    try {
      store.updateOrderBookState.loading(true);

      store.updateOrderBookState.serverConnectionStatus(
        ServerConnectionStatus.Connected,
      );

      setTimeout(() => {
        store.updateOrderBookState.loading(false);
      }, 5000);

      return await stellarOrdersViewer.StreamOrderBook({
        baseAsset: baseAsset,
        counterAsset: counterAsset,
        onUpdate: (ob) => {
          setRecord(ob);
        },
        onError: onError,
        order: "desc",
      });
    } catch (error) {
      console.error("Error loading order book:", error);
    }
  };

  return {
    fetchAndStreamOrderBook,
  };
};
