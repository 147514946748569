"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userStateToPast = userStateToPast;
exports.userStateToFuture = userStateToFuture;
exports.userGuideToPast = userGuideToPast;
const user_pb_1 = require("./user_pb");
const userStateToPastUserStateMapping = {
    [user_pb_1.State.DISABLED_STATE]: "Disabled",
    [user_pb_1.State.REGISTERED_STATE]: "Registered",
};
class UnsupportedStateTypeError extends Error {
    constructor(stateType) {
        const message = `Unsupported StateType: ${stateType}`;
        super(message);
        this.stateType = stateType;
    }
}
function userStateToPast(state) {
    if (state in userStateToPastUserStateMapping) {
        return userStateToPastUserStateMapping[state];
    }
    else {
        throw new UnsupportedStateTypeError(state);
    }
}
const pastUserStateToUserStateMapping = {
    Disabled: user_pb_1.State.DISABLED_STATE,
    Registered: user_pb_1.State.REGISTERED_STATE,
};
class UnsupportedUserStateTypeError extends Error {
    constructor(stateType) {
        const message = `Unsupported User StateType: ${stateType}`;
        super(message);
        this.stateType = stateType;
    }
}
function userStateToFuture(state) {
    if (state in pastUserStateToUserStateMapping) {
        return pastUserStateToUserStateMapping[state];
    }
    else {
        throw new UnsupportedUserStateTypeError(state);
    }
}
const userGuideToPasteMapping = {
    [user_pb_1.Guide.PLATFORM_GUIDED_JOURNEY]: "PlatformGuidedJourney",
    [user_pb_1.Guide.FIRST_TIME_LOGIN]: "FirstTimeLoginSteps",
};
class UnsupportedGuideTypeError extends Error {
    constructor(guideType) {
        const message = `Unsupported Guide Type: ${guideType}`;
        super(message);
        this.guideType = guideType;
    }
}
function validGuide(guide) {
    if (guide in userGuideToPasteMapping) {
        return true;
    }
    else {
        throw new UnsupportedGuideTypeError(guide);
    }
}
function userGuideToPast(guides) {
    const newGuides = [];
    for (const g of guides) {
        if (validGuide(g)) {
            newGuides.push(userGuideToPasteMapping[g]);
        }
    }
    return newGuides;
}
