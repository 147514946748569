import { User as FutureUser } from "@mesh/common-js/dist/iam/user_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { auditEntryActionToPast } from "@mesh/common-js/dist/audit/entry";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  userStateToPast,
  userGuideToPast,
} from "@mesh/common-js/dist/iam/user";
import { AuditEntry, AuditEntryActionType } from "james/audit";

export enum UserState {
  Registered = "Registered",
  Disabled = "Disabled",
}

export enum UserGuide {
  FirstTimeLoginSteps = "First Time Login Steps",
  PlatformGuidedJourney = "Platform Guided Journey",
  ScrollAssetDetailView = "Scroll Asset Detail View",
}

export const AllUserRegistrationStatus = [
  UserState.Registered,
  UserState.Disabled,
];

export const UserTypeName = "mesh::user/User";

export class User {
  public ["@type"]: string = UserTypeName;

  public id = "";

  public ownerID = "";

  public clientID = "";

  public personID = "";

  public email = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public roleIDs: string[] = [];

  public ausAgreementVersion = "";

  public state: UserState | string = UserState.Disabled;

  public completedGuides: UserGuide[] | string[] = [];

  constructor(user?: User) {
    if (!user) {
      return;
    }
    this.id = user.id;
    this.ownerID = user.ownerID;
    this.clientID = user.clientID;
    this.personID = user.personID;
    this.email = user.email;
    this.auditEntry = new AuditEntry(user.auditEntry);
    this.roleIDs = user.roleIDs.map((id) => id);
    this.ausAgreementVersion = user.ausAgreementVersion;
    this.state = user.state;
    this.completedGuides = user.completedGuides;
  }
}

export const userToPast = (user: FutureUser) => {
  return new User({
    ["@type"]: "mesh::user/User",
    id: user.getId(),
    ownerID: user.getOwnerid(),
    clientID: user.getClientid(),
    personID: user.getPersonid(),
    email: user.getEmail(),
    auditEntry: new AuditEntry({
      userID: user?.getAuditentry()?.getUserid() ?? "",
      time: protobufTimestampToDayjs(
        user?.getAuditentry()?.getTime() ?? new Timestamp(),
      ).format(),
      action: auditEntryActionToPast(
        user?.getAuditentry()?.getAction(),
      ) as AuditEntryActionType,
      version: user?.getAuditentry()?.getVersion() ?? 0,
    }),
    roleIDs: user.getRoleidsList().map((id) => id),
    state: userStateToPast(user.getState()),
    completedGuides: userGuideToPast(user.getCompletedguidesList()),
    ausAgreementVersion: user.getAusagreementversion(),
  });
};
