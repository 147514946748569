import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Fade,
  FadeProps,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Slide,
  SlideProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import InfoIcon from "@mui/icons-material/Info";

interface FundAccountAckProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export function FundingAcknowledgementDialog(props: FundAccountAckProps) {
  const [ack, setAck] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": isMobile
            ? {
                width: "100%",
                margin: 0,
                padding: 0,
                bottom: 0,
                position: "absolute",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : {
                width: {
                  lg: "800px",
                  sm: "100%",
                },
                maxWidth: {
                  lg: "800px",
                  sm: "672px",
                },
              },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "data-component-info": JSON.stringify({
          component_id: "fund_account_popup",
          component_business_name: "fund account popup",
          component_title: "Funding mesh mZar",
          component_driver: InteractionDriver.FundAccount,
        } as DataComponentInfo),
      }}
      fullScreen={isMobile}
      scroll="paper"
      open={props.open}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
          padding: theme.spacing(2, 2, 2, 3),
        })}
      >
        <Grid
          sx={{
            alignItems: "center",
          }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
            })}
          >
            <Typography variant="h5">Funding Mesh mZAR</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="fundAccountAck-close-button"
              data-link-info={JSON.stringify({
                content_interaction_id: "close-modal",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Button,
                content_interaction_text: "Close (x)",
                content_interaction_driver:
                  InteractionDriver.FundAccountAbandon,
              } as DataLinkInfoType)}
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({ padding: `${theme.spacing(3, 3, 3, 3)}!important` })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            py: 2,
            px: 3,
            width: "100%",
            backgroundColor: theme.palette.custom.lavenderLight,
            borderRadius: "10px",
            height: isMobile ? "auto" : theme.spacing(7),
            alignItems: isMobile ? "flex-start" : "center",
            margin: isMobile ? theme.spacing(1, 0) : theme.spacing(1, 0, 4, 0),
          })}
        >
          <IconButton sx={{ color: "black", ":hover": { color: "black" } }}>
            <InfoIcon
              sx={{
                ":hover": {
                  color: "black",
                },
              }}
            />
          </IconButton>
          <Typography
            sx={{
              fontSize: "14px",
              ml: 1,
              my: 1,
            }}
            color={"black"}
            variant="body1"
          >
            Capitec Bank has restricted payments for crypto asset trading. If
            you’re a Capitec customer looking to fund your Mesh account,{" "}
            <Link
              sx={{
                textDecoration: "underline",
                fontSize: "14px",
                color: "black",
                ":hover": {
                  color: "black",
                },
              }}
              href="https://support.mesh.trade/support/solutions/articles/80001156820-how-do-i-fund-my-mesh-account-from-a-capitec-bank-account-"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </Link>{" "}
            to learn more.
          </Typography>
        </Box>
        <Typography
          sx={(theme) => ({
            padding: theme.spacing(2, 0, 0, 1),
            ...(isMobile && {
              padding: theme.spacing(2, 0, 3, 1),
            }),
          })}
          color={"textPrimary"}
          fontSize="16px"
          variant="h3"
        >
          To ensure a smooth funding process, please be aware of the following:
        </Typography>
        <List
          component="ol"
          sx={(theme) => ({
            listStyleType: "decimal",
            pl: 3,
            ".MuiListItem-root": {
              // Style the marker directly
              color: theme.palette.text.secondary,
              variant: theme.typography.body2,
            },
          })}
        >
          <ListItem
            component="li"
            sx={{
              display: "list-item",
              listStyleType: "inherit",
              paddingLeft: 1,
              color: "textSecondary",
              variant: "body1",
              py: 0, //leave this there is a default of 8 px so I am overiding it here
            }}
          >
            <ListItemText
              primary="Always use your unique reference when funding your Mesh account."
              primaryTypographyProps={{
                color: "textSecondary",
                variant: "body1",
              }}
            />
          </ListItem>
          <ListItem
            component="li"
            sx={{
              display: "list-item",
              listStyleType: "inherit",
              paddingLeft: 1,
              color: "textSecondary",
              variant: "body1",
              py: 0, //leave this there is a default of 8 px so I am overiding it here
            }}
          >
            <ListItemText
              primary="Funds can take anywhere from an hour to up to 2 days to reflect in your account."
              primaryTypographyProps={{
                color: "textSecondary",
                variant: "body1",
              }}
            />
          </ListItem>
        </List>
        <FormControlLabel
          sx={[
            (theme) => ({
              marginRight: theme.spacing(2),
              padding: isMobile
                ? theme.spacing(8, 0, 0, 1)
                : theme.spacing(0, 0, 0, 1),
            }),
            isMobile && {
              margin: theme.spacing(1),
            },
          ]}
          control={
            <Checkbox
              checked={ack}
              onChange={() => setAck(!ack)}
              data-link-info={JSON.stringify({
                content_interaction_id: "accept-tnc",
                content_interaction_action: InteractionAction.Check,
                content_interaction_text: "fund mesh - i acknowledge",
                content_interaction_type: InteractionType.Checkbox,
                content_interaction_driver: InteractionDriver.FundAccount,
              } as DataLinkInfoType)}
              name="checkedB"
              color="primary"
              id="fundAccountAck-ack-checkbox"
            />
          }
          label="I acknowledge the funding guidelines"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            data-link-info={JSON.stringify({
              content_interaction_id: "next-steps",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: "button",
              content_interaction_text: "continue",
              content_interaction_driver: InteractionDriver.FundAccount,
            } as DataLinkInfoType)}
            sx={[isMobile && { height: 48 }]}
            fullWidth={isMobile}
            id="fundAccountAck-continue-button"
            onClick={props.onContinue}
            disabled={!ack}
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" {...tprops} />;
};

const fadeTransition = (tprops: FadeProps) => {
  return <Fade in {...tprops} />;
};
