"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auditEntryActionToPast = auditEntryActionToPast;
exports.auditEntryActionToFuture = auditEntryActionToFuture;
const entry_pb_1 = require("./entry_pb");
const auditEntryToStringMapping = {
    [entry_pb_1.Action.CREATED_ACTION]: "CREATED",
    [entry_pb_1.Action.MODIFIED_ACTION]: "MODIFIED",
    [entry_pb_1.Action.DELETED_ACTION]: "DELETED",
    [entry_pb_1.Action.RESTORED_ACTION]: "RESTORED",
    [entry_pb_1.Action.UNDEFINED_ACTION]: "Undefined",
};
class UnsupportedAuditEntryActionTypeError extends Error {
    constructor(action) {
        const message = `Unsupported Action Type: ${action}`;
        super(message);
        this.action = action;
    }
}
function auditEntryActionToPast(action) {
    if (!action) {
        return auditEntryToStringMapping[entry_pb_1.Action.UNDEFINED_ACTION];
    }
    if (action in auditEntryToStringMapping) {
        return auditEntryToStringMapping[action];
    }
    else {
        throw new UnsupportedAuditEntryActionTypeError(action);
    }
}
const pastEntryActionToFutureEntryActionMapping = {
    CREATED: entry_pb_1.Action.CREATED_ACTION,
    MODIFIED: entry_pb_1.Action.MODIFIED_ACTION,
    DELETED: entry_pb_1.Action.DELETED_ACTION,
    RESTORED: entry_pb_1.Action.RESTORED_ACTION,
    Undefined: entry_pb_1.Action.UNDEFINED_ACTION,
};
class UnsupportedEntryActionTypeError extends Error {
    constructor(action) {
        const message = `Unsupported Action Type: ${action}`;
        super(message);
        this.action = action;
    }
}
function auditEntryActionToFuture(action) {
    if (action in auditEntryToStringMapping) {
        return pastEntryActionToFutureEntryActionMapping[action];
    }
    else {
        throw new UnsupportedEntryActionTypeError(action);
    }
}
