import React, { SetStateAction, useState, Dispatch } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { MobileNumberVerificationCard } from "../MobileNumberVerificationCard/MobileNumberVerificationCard";
import { useApplicationContext } from "context/Application/Application";
import { useNavigate } from "react-router-dom";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { PhoneNumberInput } from "components/PhoneNumberInput/PhoneNumberInput";
import { CountryCode } from "libphonenumber-js";
import { SMSTOTPSender } from "james/security/verification/SMSTOTPSender";
import { MobileNumberVerificationStep } from "../../MobileNumberVerification";
import { useErrorContext } from "context/Error";
import { useSnackbar } from "notistack";
import { Close as CloseIcon } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";

export interface CaptureMobileNumberCardProps {
  setViewState: Dispatch<SetStateAction<MobileNumberVerificationStep>>;
  closeDialog?: Dispatch<SetStateAction<boolean>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  countryCode: CountryCode;
  setCountryCode: Dispatch<SetStateAction<CountryCode>>;
  startTimer: (duration: number) => void;
  firstTimeLogin: boolean;
  isMobile: boolean;
}

export function CaptureMobileNumberCard({
  setViewState,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  startTimer,
  firstTimeLogin,
  isMobile,
  closeDialog,
}: CaptureMobileNumberCardProps) {
  const { authContext } = useApplicationContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState(true);

  const handleSendVerificationCode = async () => {
    setLoading(true);

    const postProcessedPhoneNumber =
      "+27" +
      (phoneNumber.replace(/\D/g, "").length === 10
        ? // if the length is 10 characters then strip off the leading 0
          phoneNumber.replace(/\D/g, "").slice(1)
        : // otherwise do nothing
          phoneNumber.replace(/\D/g, ""));

    try {
      await SMSTOTPSender.SendSMSTOTP({
        context: authContext,
        phoneNumber: postProcessedPhoneNumber,
      });
      enqueueSnackbar("Verification code sent", {
        variant: "success",
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
    }
    setLoading(false);
    startTimer(30);
    setViewState(MobileNumberVerificationStep.verifyCode);
  };

  if (!firstTimeLogin && isMobile) {
    return (
      <Drawer
        sx={{
          zIndex: 1400,
        }}
        className={"capture-mobile-number-drawer"}
        open={openDialog}
        anchor="bottom"
      >
        <DialogTitle sx={{ height: "52px" }}>
          <Typography fontSize="16px">Verify Your Mobile Number</Typography>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="Verify-mobile-number-dialog-close-button"
                  size="small"
                  onClick={() => {
                    setOpenDialog(false);
                    if (closeDialog) {
                      closeDialog(false);
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            width: "100%",
            display: "grid",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(3),
          })}
        >
          <Typography
            fontSize="20px"
            sx={(theme) => ({
              paddingTop: 3,
              color: theme.palette.text.primary,
            })}
            fontWeight="600"
          >
            Let&apos;s secure your account
          </Typography>
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.hint,
              padding: theme.spacing(3, 0),
            })}
            fontSize="14px"
            fontWeight="400"
          >
            Enter your personal mobile number
          </Typography>
          <PhoneNumberInput
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            disableCountrySelect={false}
            inputBGColor="background.paper"
            setIsValidPhoneNumber={setIsValidPhoneNumber}
            isValidPhoneNumber={isValidPhoneNumber}
          />
          <Typography
            fontSize="14px"
            sx={(theme) => ({
              paddingTop: 3,
              color: theme.palette.text.hint,
            })}
            fontWeight="600"
          >
            Please Note:
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.hint,
              margin: theme.spacing(0, 0, 5),
            })}
            fontSize="12px"
            fontWeight="400"
          >
            Mesh will use your number for authentication purposes and to help
            keep your account secure. We'll only send you promotional messages
            with your explicit consent.
          </Typography>
          <Button
            sx={(theme) => ({
              height: theme.spacing(5.25),
              width: "100%",
              marginBottom: theme.spacing(1),
            })}
            id="mobileNumberVerification-submit-button"
            disabled={phoneNumber === "" || loading}
            onClick={handleSendVerificationCode}
            variant="contained"
            color="secondary"
            size="large"
          >
            Send Code
          </Button>
        </DialogContent>
      </Drawer>
    );
  }

  if (!firstTimeLogin) {
    return (
      <Dialog
        className={"capture-mobile-number-dialog"}
        open={openDialog}
        sx={{ width: "100%", height: "423", justifySelf: "center" }}
      >
        <DialogTitle sx={{ height: "52px" }}>
          <Typography fontSize="16px">Verify Your Mobile Number</Typography>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="Verify-mobile-number-dialog-close-button"
                  size="small"
                  onClick={() => {
                    setOpenDialog(false);
                    if (closeDialog) {
                      closeDialog(false);
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            width: "392px",
            display: "grid",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(3),
          })}
        >
          <Typography
            fontSize="20px"
            sx={(theme) => ({
              paddingTop: 3,
              color: theme.palette.text.primary,
            })}
            fontWeight="600"
          >
            Let&apos;s secure your account
          </Typography>
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.hint,
              padding: theme.spacing(3, 0),
            })}
            fontSize="14px"
            fontWeight="400"
          >
            Enter your personal mobile number
          </Typography>
          <PhoneNumberInput
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            disableCountrySelect={false}
            inputBGColor="background.paper"
            setIsValidPhoneNumber={setIsValidPhoneNumber}
            isValidPhoneNumber={isValidPhoneNumber}
          />
          <Typography
            fontSize="14px"
            sx={(theme) => ({
              paddingTop: 2,
              color: theme.palette.text.hint,
            })}
            fontWeight="600"
          >
            Please Note:
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.hint,
              margin: theme.spacing(0, 0, 3),
            })}
            fontSize="12px"
            fontWeight="400"
          >
            Mesh will use your number for authentication purposes and to help
            keep your account secure. We'll only send you promotional messages
            with your explicit consent.
          </Typography>
          <Button
            sx={(theme) => ({
              height: theme.spacing(5.25),
              marginBottom: theme.spacing(1),
              justifySelf: "end",
            })}
            id="mobileNumberVerification-submit-button"
            disabled={phoneNumber === "" || loading}
            onClick={handleSendVerificationCode}
            variant="contained"
            color="secondary"
            size="large"
          >
            Send Code
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <MobileNumberVerificationCard
      linksFooter={
        <Link
          id="mobileNumberVerification-logout-link"
          sx={(theme) => ({
            fontWeight: "bold",
            paddingBottom: theme.spacing(5),
          })}
          onClick={async () => {
            navigate("/market/markets");
          }}
          fontSize={"16px"}
          component="button"
          underline="hover"
          color="secondary"
        >
          Skip this Step
        </Link>
      }
    >
      <VerticalSlide transitionDuration={0.5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5, 0, 4, 0),
          })}
          fontSize="20px"
          fontWeight="600"
        >
          Verify your mobile number
        </Typography>
      </VerticalSlide>
      <VerticalSlide transitionDuration={0.5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(0, 0, 3, 0),
          })}
          fontSize="14px"
          fontWeight="400"
        >
          Enter your personal mobile number
        </Typography>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={0.67}>
        <PhoneNumberInput
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          disableCountrySelect={false}
          setIsValidPhoneNumber={setIsValidPhoneNumber}
          isValidPhoneNumber={isValidPhoneNumber}
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={0.5}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.hint,
            margin: theme.spacing(3, 0, 4),
          })}
          fontSize="12px"
          fontWeight="400"
        >
          Mesh will use your number for authentication purposes and to help keep
          your account secure. We'll only send you promotional messages with
          your explicit consent.
        </Typography>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          sx={(theme) => ({
            height: theme.spacing(5.25),
            width: "100%",
            marginBottom: theme.spacing(19),
          })}
          id="mobileNumberVerification-submit-button"
          disabled={phoneNumber === "" || loading || !isValidPhoneNumber}
          onClick={handleSendVerificationCode}
          variant="contained"
          color="secondary"
          size="large"
        >
          Send Code
        </Button>
      </VerticalSlide>
    </MobileNumberVerificationCard>
  );
}
