import React, { Dispatch, SetStateAction, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import background from "assets/images/background/background.png";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { Box } from "@mui/system";
import { CaptureMobileNumberCard } from "./components/MobileNumberCaptureCard/CaptureMobileNumberCard";
import { VerifyMobileNumberCard } from "./components/VerifyMobileNumberCard/VerifyMobileNumberCard";
import { countries } from "james/country";
import { CountryCode } from "libphonenumber-js";
import { useTimer } from "hooks/useTimer";
import { useNavigate } from "react-router-dom";
import config from "react-global-configuration";

export enum MobileNumberVerificationStep {
  sendVerificationCode = "sendVerificationCode",
  verifyCode = "verifyCode",
}

export function MobileNumberVerification({
  renderDialog,
  firstTimeLogin,
  closeDialog,
  setVerifiedNumber,
}: {
  renderDialog?: boolean;
  firstTimeLogin: boolean;
  closeDialog?: Dispatch<SetStateAction<boolean>>;
  setVerifiedNumber?: Dispatch<SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const { timerValue, timerCompleted, startTimer, stopTimer } = useTimer();
  const [countryCode, setCountryCode] = useState(
    countries.filter((c) => c.value === "ZA")[0].value as CountryCode,
  );
  const [viewState, setViewState] = useState<MobileNumberVerificationStep>(
    MobileNumberVerificationStep.sendVerificationCode,
  );
  const navigate = useNavigate();

  const setOTPValues = (): [number, boolean] => {
    return [timerValue, timerCompleted];
  };

  if (!config.get("allowMFAVerification")) {
    navigate("/market");
    return;
  }

  return (
    <>
      {!isMobile && !renderDialog ? (
        <Box
          sx={{
            display: "grid",
            backgroundImage: `url(${background})`,
            gridTemplateRows: "auto 1fr",
            backgroundSize: "cover",
            height: "100vh",
            overflowY: useMediaQuery(theme.breakpoints.down("md"))
              ? "hidden"
              : "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            {(() => {
              switch (viewState) {
                case MobileNumberVerificationStep.sendVerificationCode:
                  return (
                    <CaptureMobileNumberCard
                      setViewState={setViewState}
                      phoneNumber={phoneNumber}
                      setPhoneNumber={setPhoneNumber}
                      countryCode={countryCode}
                      setCountryCode={setCountryCode}
                      startTimer={startTimer}
                      firstTimeLogin={firstTimeLogin}
                      isMobile={isMobile}
                    />
                  );

                case MobileNumberVerificationStep.verifyCode:
                  return (
                    <VerifyMobileNumberCard
                      phoneNumber={phoneNumber}
                      countryCode={countryCode}
                      setOTPValues={setOTPValues}
                      stopTimer={stopTimer}
                      firstTimeLogin={firstTimeLogin}
                      isMobile={isMobile}
                      setViewState={setViewState}
                      closeDialog={closeDialog}
                      setVerifiedNumber={setVerifiedNumber}
                    />
                  );
              }
            })()}
          </Box>
        </Box>
      ) : (
        <>
          {(() => {
            switch (viewState) {
              case MobileNumberVerificationStep.sendVerificationCode:
                return (
                  <CaptureMobileNumberCard
                    setViewState={setViewState}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    startTimer={startTimer}
                    firstTimeLogin={firstTimeLogin}
                    isMobile={isMobile}
                    closeDialog={closeDialog}
                  />
                );

              case MobileNumberVerificationStep.verifyCode:
                return (
                  <VerifyMobileNumberCard
                    phoneNumber={phoneNumber}
                    countryCode={countryCode}
                    setOTPValues={setOTPValues}
                    stopTimer={stopTimer}
                    firstTimeLogin={firstTimeLogin}
                    isMobile={isMobile}
                    setViewState={setViewState}
                    closeDialog={closeDialog}
                    setVerifiedNumber={setVerifiedNumber}
                  />
                );
            }
          })()}
        </>
      )}
    </>
  );
}
