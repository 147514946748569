import React from "react";
import {
  Box,
  Button,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ExchangeCard } from "./components/ExchangeCard";
import { ExchangeActions, ExchangeStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { MechanismType } from "james/market";

interface ExchangeListingsProps {
  exchangeStore: ExchangeStore & ExchangeActions;
}

export const ExchangeListings = ({ exchangeStore }: ExchangeListingsProps) => {
  const navigate = useNavigate();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        mt: 3,
        maxWidth: 1280,
        width: "100%",
        px: { sm: 4, xs: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: 1,
          ml: 1,
        }}
      >
        {smDown ? (
          <Link underline="none" onClick={() => navigate(-1)}>{`< Back`}</Link>
        ) : (
          <Typography fontWeight="bold" variant="h4">
            Exchange
          </Typography>
        )}
      </Box>

      {!mdDown && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "45% repeat(2, 1fr)",
            px: 3,
          }}
        >
          <Typography>Pair</Typography>
          <Typography>Last Traded Price</Typography>
          <Typography sx={{ mr: "228px", textAlign: "end" }}>
            Actions
          </Typography>
        </Box>
      )}

      <Box
        className="meshScroll"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflowY: "auto",
          p: 1,
        }}
      >
        {exchangeStore.marketListings.map((ml, idx) => {
          const mechanism = ml.listingMarketMechanisms.find(
            (v) => v.type === MechanismType.LimitOrder,
          );
          if (!mechanism) return null;
          return (
            <>
              {mechanism.quoteParameters.map((qp, i) => {
                return (
                  <ExchangeCard
                    key={`${idx}-${i}`}
                    marketListingViewModel={ml}
                    quoteToken={qp.quoteToken}
                  />
                );
              })}
            </>
          );
        })}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            maxWidth: 332,
            mb: 2,
          }}
        >
          Don't see the asset you're looking for? <br /> View the Mesh Markets.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/market/markets")}
        >
          <Typography>View Markets</Typography>
        </Button>
      </Box>
    </Box>
  );
};
