import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from "@mui/icons-material";
import banner from "assets/images/kycOutstandingDialog/banner.png";
import bannerMini from "assets/images/kycOutstandingDialog/banner_mini.png";
import cx from "classnames";
import { ClientKYCStatus } from "james/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebaseContext } from "context/Firebase";
import { Client, ClientKind } from "james/client/Client";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import { useApplicationContext } from "context/Application/Application";
import { UserState } from "james/user/User";
import { useAppTourContext } from "context/AppTour/AppTour";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage";

const PREFIX = "KYCOutstandingDialog";

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  kycRejectedDialogContent: `${PREFIX}-kycRejectedDialogContent`,
  banner: `${PREFIX}-banner`,
  boldFont: `${PREFIX}-boldFont`,
  addedMargin: `${PREFIX}-addedMargin`,
  fixedHeight52: `${PREFIX}-fixedHeight52`,
  contentSection: `${PREFIX}-contentSection`,
  warningTextColor: `${PREFIX}-warningTextColor`,
  warningMessageLayout: `${PREFIX}-warningMessageLayout`,
  errorLogoSize: `${PREFIX}-errorLogoSize`,
  buttonSize: `${PREFIX}-buttonSize`,
  linkCursor: `${PREFIX}-linkCursor`,
  mobileDialogContent: `${PREFIX}-mobileDialogContent`,
  mobileDialogTitle: `${PREFIX}-mobileDialogTitle`,
  mobileBanner: `${PREFIX}-mobileBanner`,
  mobileWarningMessageLayout: `${PREFIX}-mobileWarningMessageLayout`,
  mobileContentSection: `${PREFIX}-mobileContentSection`,
  drawer: `${PREFIX}-drawer`,
  mobileKYCRejectedDialogContent: `${PREFIX}-mobileKYCRejectedDialogContent`,
  mobileButtonHeight: `${PREFIX}-mobileButtonHeight`,
  mobileButtonBottomMargin: `${PREFIX}-mobileButtonBottomMargin`,
  mobileCloseButton: `${PREFIX}-mobileCloseButton`,
  removePadding: `${PREFIX}-removePadding`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "200px auto",
    gridRowGap: theme.spacing(1),
    padding: "0px",
  },

  [`& .${classes.kycRejectedDialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "repeat(4, auto)",
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(4, 4, 4, 3),
  },

  [`& .${classes.banner}`]: {
    width: "100%",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    paddingTop: "47px",
    paddingLeft: "52px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  [`& .${classes.boldFont}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.addedMargin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.fixedHeight52}`]: {
    height: "52px",
  },

  [`& .${classes.contentSection}`]: {
    display: "grid",
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(5),
  },

  [`& .${classes.warningTextColor}`]: {
    color: theme.palette.warning.light,
  },

  [`& .${classes.warningMessageLayout}`]: {
    display: "grid",
    gridTemplateColumns: "24px 0.7fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.errorLogoSize}`]: {
    fontSize: "20px",
  },

  [`& .${classes.buttonSize}`]: {
    maxWidth: "229px",
  },

  [`& .${classes.linkCursor}`]: {
    cursor: "pointer",
  },

  //
  // mobile view css
  //

  [`& .${classes.mobileDialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "200px auto",
    backgroundColor: theme.palette.custom.midnight,
    gridRowGap: theme.spacing(1),
    padding: "0px",
  },

  [`& .${classes.mobileDialogTitle}`]: {
    padding: 0,
  },

  [`& .${classes.mobileBanner}`]: {
    width: "100%",
    backgroundImage: `url(${bannerMini})`,
    backgroundSize: "cover",
    paddingTop: "70px",
    paddingLeft: "24px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  [`& .${classes.mobileWarningMessageLayout}`]: {
    display: "grid",
    gridTemplateColumns: "24px 1fr",
    gridColumnGap: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.mobileContentSection}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "repeat(5, auto)",
    padding: theme.spacing(2, 3, 5, 3),
  },

  [`& .${classes.drawer}`]: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },

  [`& .${classes.mobileKYCRejectedDialogContent}`]: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "repeat(4, auto)",
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(2, 3, 5, 3),
  },

  [`& .${classes.mobileButtonHeight}`]: {
    height: 48,
  },

  [`& .${classes.mobileButtonBottomMargin}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.mobileCloseButton}`]: {
    color: theme.palette.text.tertiary,
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.removePadding}`]: {
    padding: 0,
  },
}));

export function KYCOutstandingDialog() {
  const { tourInProgress } = useAppTourContext();
  const { firebaseAuthenticated } = useFirebaseContext();
  const { userAuthenticated, loginClaims, myClient } = useApplicationContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;
  const width = useWidth();
  const { getStore } = useLocalStorage();
  const navigate = useNavigate();

  const isMobile = useMemo(() => isWidthDown("sm", width), [width]);

  const location = useLocation();
  // Mark the dialog "already visited" if the redirectURL is set in the store.
  // This is required to prevent this dialog from being open behind a redirect popup.
  // (Note: only relevant for asset redirect at the dayjs.)
  const [dialogAlreadyVisited, setDialogAlreadyVisited] = useState(
    !!getStore().redirectURL,
  );

  const showKYCRejectedDialog = useMemo(
    () =>
      loggedIn &&
      loginClaims.userState === UserState.Registered &&
      !dialogAlreadyVisited &&
      !location.pathname.includes("/kyc") &&
      !location.pathname.includes("/verify-mobile-number") &&
      new Client(myClient).kycStatus === ClientKYCStatus.Outstanding &&
      new Client(myClient).kycStatusReason !== "" &&
      new Client(myClient).clientKind === ClientKind.IndividualType,
    [dialogAlreadyVisited, loggedIn, myClient],
  );

  const showDialog = useMemo(
    () =>
      !tourInProgress &&
      loggedIn &&
      loginClaims.userState === UserState.Registered &&
      !dialogAlreadyVisited &&
      !location.pathname.includes("/kyc") &&
      !location.pathname.includes("/verify-mobile-number") &&
      new Client(myClient).kycStatus === ClientKYCStatus.Outstanding &&
      new Client(myClient).kycStatusReason === "" &&
      new Client(myClient).clientKind === ClientKind.IndividualType,
    [dialogAlreadyVisited, loggedIn, myClient, tourInProgress],
  );

  const handleDoLaterClick = () => {
    setDialogAlreadyVisited(true);
  };

  const handleStartKYCProcessClick = () => {
    navigate("/kyc");
  };

  // do not render if the client entity is still being retrieved
  if (!myClient) {
    return null;
  }

  if (showKYCRejectedDialog) {
    if (isMobile) {
      return (
        <Drawer
          classes={{ paper: classes.drawer }}
          open={showKYCRejectedDialog}
          anchor="bottom"
        >
          <DialogTitle>
            <Typography
              className={classes.addedMargin}
              variant="h5"
              children="Identity Verification - Incomplete"
            />
            <IconButton
              size="small"
              onClick={handleDoLaterClick}
              className={classes.mobileCloseButton}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.mobileKYCRejectedDialogContent }}
          >
            <Typography
              sx={(theme) => ({
                marginTop: theme.spacing(4),
                color: theme.palette.text.secondary,
              })}
              variant="body1"
              children={`Identity verification attempt unsuccessful!
                  Please review your information and the documents you have submitted.`}
            />
            <div className={classes.mobileWarningMessageLayout}>
              <ErrorIcon
                className={cx(classes.errorLogoSize, classes.warningTextColor)}
              />
              <Typography
                variant="body2"
                className={classes.warningTextColor}
                children="You won’t be able to fund your account or trade on Mesh unless you have been verified."
              />
            </div>
            <Button
              id="mobileKYCOutstandingDialog-startTheKYCProcess-button"
              fullWidth
              variant="contained"
              className={cx(
                classes.mobileButtonHeight,
                classes.mobileButtonBottomMargin,
              )}
              color="primary"
              onClick={handleStartKYCProcessClick}
            >
              review information
            </Button>
            <Typography
              variant="body1"
              align="center"
              className={classes.boldFont}
              color="textSecondary"
              children={
                <Link
                  id="mobileKYCOutstandingDialog-doItLater-link"
                  onClick={handleDoLaterClick}
                  className={classes.linkCursor}
                  component="span"
                  color="secondary"
                  data-link-info={JSON.stringify({
                    content_interaction_id: "kyc-required-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "I will do this later",
                    content_interaction_type: InteractionType.Link,
                    content_interaction_driver: InteractionDriver.kycStart,
                  } as DataLinkInfoType)}
                >
                  I will do this later
                </Link>
              }
            />
          </DialogContent>
        </Drawer>
      );
    }

    return (
      <StyledDialog open={showKYCRejectedDialog} maxWidth="lg">
        <DialogTitle>
          <Typography
            className={classes.addedMargin}
            variant="h5"
            children="Identity Verification - Incomplete"
          />
        </DialogTitle>
        <DialogContent classes={{ root: classes.kycRejectedDialogContent }}>
          <Typography
            sx={(theme) => ({
              marginTop: theme.spacing(4),
              color: theme.palette.text.secondary,
            })}
            variant="body1"
            children={`Identity verification attempt unsuccessful!
                  Please review your information and the documents you have submitted.`}
          />
          <div className={classes.warningMessageLayout}>
            <ErrorIcon
              className={cx(classes.errorLogoSize, classes.warningTextColor)}
            />
            <Typography
              variant="body2"
              className={classes.warningTextColor}
              children="You won’t be able to fund your account or trade on Mesh unless you have been verified."
            />
          </div>
          <Button
            className={classes.buttonSize}
            id="kycOutstandingDialog-startTheKYCProcess-button"
            variant="contained"
            color="primary"
            onClick={handleStartKYCProcessClick}
          >
            review information
          </Button>
          <Typography
            variant="body2"
            color="textSecondary"
            children={
              <Link
                id="kycOutstandingDialog-doItLater-link"
                onClick={handleDoLaterClick}
                className={classes.linkCursor}
                component="span"
                color="secondary"
                data-link-info={JSON.stringify({
                  content_interaction_id: "kyc-required-link",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "I will do this later",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.kycStart,
                } as DataLinkInfoType)}
              >
                I will do this later
              </Link>
            }
          />
        </DialogContent>
      </StyledDialog>
    );
  }

  if (isMobile) {
    return (
      <StyledDialog
        fullScreen
        open={showDialog}
        PaperProps={{
          "data-component-info": JSON.stringify({
            component_id: "kyc_required_popup",
            component_business_name: "kyc required popup",
            component_title: "verification required",
            component_driver: InteractionDriver.kycUser,
          } as DataComponentInfo),
        }}
      >
        <DialogContent
          sx={(theme) => ({
            width: "100%",
            display: "grid",
            gridTemplateRows: "200px auto",
            backgroundColor: theme.palette.custom.midnight,
            gridRowGap: theme.spacing(1),
            padding: "0px",
          })}
        >
          <Box
            sx={(theme) => ({
              width: "100%",
              backgroundImage: `url(${bannerMini})`,
              backgroundSize: "cover",
              paddingTop: "70px",
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            })}
          >
            <Typography
              className={classes.boldFont}
              variant="h3"
              children={`Hello ${loginClaims.firstName},`}
            />
            <Typography
              className={classes.boldFont}
              variant="h4"
              children={"let's confirm your identity"}
            />
          </Box>
          <Box
            sx={(theme) => ({
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(2, 3, 5, 3),
              gap: 4,
            })}
          >
            <Typography
              className={classes.boldFont}
              variant="h4"
              children="Verification Required"
            />
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="body1"
              children={`Trust is our primary concern. To ensure that you can trade with absolute
                 confidence with any other party on Mesh, everyone using the platform is
                  properly vetted and verified.`}
            />
            <div
              className={cx(
                classes.mobileWarningMessageLayout,
                classes.removePadding,
              )}
            >
              <ErrorIcon
                className={cx(classes.errorLogoSize, classes.warningTextColor)}
              />
              <Typography
                variant="body2"
                className={classes.warningTextColor}
                children="You won’t be able to fund your account or trade on Mesh unless you have been verified."
              />
            </div>
            <Button
              id="mobileKYCOutstandingDialog-startTheKYCProcess-button"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.mobileButtonHeight}
              onClick={handleStartKYCProcessClick}
              data-link-info={JSON.stringify({
                content_interaction_id: "kyc-required-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "start now",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.kycStart,
              } as DataLinkInfoType)}
            >
              Start Now
            </Button>
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{ mt: "auto" }}
              children={
                <Link
                  id="mobileKYCOutstandingDialog-doItLater-link"
                  onClick={handleDoLaterClick}
                  className={cx(classes.linkCursor, classes.boldFont)}
                  component="span"
                  color="secondary"
                  data-link-info={JSON.stringify({
                    content_interaction_id: "kyc-required-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "I will do this later",
                    content_interaction_type: InteractionType.Link,
                    content_interaction_driver: InteractionDriver.kycStart,
                  } as DataLinkInfoType)}
                >
                  I will do this later
                </Link>
              }
            />
          </Box>
        </DialogContent>
      </StyledDialog>
    );
  }

  return (
    <StyledDialog
      open={showDialog}
      maxWidth="md"
      PaperProps={{
        "data-component-info": JSON.stringify({
          component_id: "kyc_required_popup",
          component_business_name: "kyc required popup",
          component_title: "verification required",
          component_driver: InteractionDriver.kycUser,
        } as DataComponentInfo),
      }}
    >
      <DialogTitle className={classes.fixedHeight52} />
      <DialogContent
        className={"meshScroll"}
        classes={{ root: classes.dialogContent }}
      >
        <div className={classes.banner}>
          <Typography
            variant="h1"
            children={`Hello ${loginClaims.firstName},`}
          />
          <Typography
            className={classes.boldFont}
            variant="h1"
            children={"let's confirm your identity"}
          />
        </div>
        <div className={classes.contentSection}>
          <Typography
            className={classes.boldFont}
            variant="h4"
            children="Verification Required"
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
            children={`Trust is our primary concern. To ensure that you can trade with absolute
                 confidence with any other party on Mesh, everyone using the platform is
                  properly vetted and verified.`}
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
            children={`User verification is a mandatory process of identifying and verifying your identity. We
                 therefore ask that you provide us with all the information requested.`}
          />
          <div className={classes.warningMessageLayout}>
            <ErrorIcon
              className={cx(classes.errorLogoSize, classes.warningTextColor)}
            />
            <Typography
              variant="body2"
              className={classes.warningTextColor}
              children="You won’t be able to fund your account or trade on Mesh unless you have been verified."
            />
          </div>
          <Button
            className={classes.buttonSize}
            id="kycOutstandingDialog-startTheKYCProcess-button"
            variant="contained"
            color="primary"
            onClick={handleStartKYCProcessClick}
            data-link-info={JSON.stringify({
              content_interaction_id: "kyc-required-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "start now",
              content_interaction_type: InteractionType.Button,
              content_interaction_driver: InteractionDriver.kycStart,
            } as DataLinkInfoType)}
          >
            Start Now
          </Button>
          <Typography
            variant="body2"
            color="textSecondary"
            children={
              <Link
                id="kycOutstandingDialog-doItLater-link"
                onClick={handleDoLaterClick}
                className={classes.linkCursor}
                component="span"
                color="secondary"
                data-link-info={JSON.stringify({
                  content_interaction_id: "kyc-required-link",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "I will do this later",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.kycStart,
                } as DataLinkInfoType)}
              >
                I will do this later
              </Link>
            }
          />
        </div>
      </DialogContent>
    </StyledDialog>
  );
}
