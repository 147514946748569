import React, { ReactNode } from "react";
import { Box, Card, Theme, useMediaQuery } from "@mui/material";
import { useWindowSize } from "utilities/general";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";

export function MobileNumberVerificationCard({
  children,
  linksFooter,
}: {
  children: ReactNode;
  linksFooter: ReactNode;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const windowSize = useWindowSize();

  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        width: !isMobile ? "374px" : windowSize[0],
        backgroundColor: theme.palette.custom.midnight,
        padding: isMobile ? theme.spacing(0, 3) : theme.spacing(0, 4),
        maxWidth: !isMobile ? "374px" : windowSize[0],
        height: !isMobile ? "568px" : windowSize[1],
        alignItems: "center",
        overflowY: !isMobile ? "hidden" : "auto",
        borderRadius: isMobile ? 0 : "5px",
      })}
    >
      {isMobile && (
        <Box
          component="img"
          sx={{
            width: 220,
            height: 100,
          }}
          src={meshMiniLogo}
        />
      )}
      {children}
      {linksFooter}
    </Card>
  );
}
