import { create } from "zustand";
import {
  Person,
  PersonContactDetails,
  SourceOfIncomeAndWealthType,
} from "james/legal/person";
import { InfluentialPersonStatus } from "james/legal/person/Person";
import { IdentificationType } from "james/legal";
import { Address } from "james/location";

interface TCompanyDetailsViewState {
  loading: boolean;
  firstName: string;
  middleNames: string;
  lastName: string;
  dateOfBirth: string;
  nationality: string;
  influentialPerson: InfluentialPersonStatus | string;
  identificationType: IdentificationType | string;
  identificationNumber: string;
  identificationNumberExpiry: string;
  contactDetails: PersonContactDetails;
  residentialAddress: Address;
  sourceOfWealth: SourceOfIncomeAndWealthType | string;
  sourceOfIncome: SourceOfIncomeAndWealthType | string;
  // cached company
  cachedPerson: Person;
  lastUpdateDate: string;
  lastUpdateBy: string;
}

interface TCompanyDetailsViewActions {
  setLoading: (val: boolean) => void;
  setFirstName: (val: string) => void;
  setMiddleNames: (val: string) => void;
  setLastName: (val: string) => void;
  setDateOfBirth: (val: string) => void;
  setNationality: (val: string) => void;
  setInfluentialPerson: (val: InfluentialPersonStatus | string) => void;
  setIdentificationType: (val: IdentificationType | string) => void;
  setIdentificationNumber: (val: string) => void;
  setIdentificationNumberExpiry: (val: string) => void;
  setContactDetails: (val: PersonContactDetails) => void;
  setResidentialAddress: (val: Address) => void;
  setSourceOfWealth: (val: SourceOfIncomeAndWealthType | "") => void;
  setSourceOfIncome: (val: SourceOfIncomeAndWealthType | "") => void;
  setFromState: (val: Person) => void;
  setCachedPerson: (val: Person) => void;
  setLastUpdateDate: (val: string) => void;
  setLastUpdateBy: (val: string) => void;
  getPersonObject: () => Person;
  clear: () => void;
}

const initialPersonViewState: TCompanyDetailsViewState = {
  loading: false,
  firstName: "",
  middleNames: "",
  lastName: "",
  dateOfBirth: "",
  nationality: "",
  influentialPerson: "",
  identificationType: "",
  identificationNumber: "",
  identificationNumberExpiry: "",
  contactDetails: new PersonContactDetails(),
  residentialAddress: new Address(),
  sourceOfWealth: "",
  sourceOfIncome: "",
  cachedPerson: new Person(),
  lastUpdateDate: "",
  lastUpdateBy: "",
};

export const usePersonViewStore = create<
  TCompanyDetailsViewState & TCompanyDetailsViewActions
>((set, get) => ({
  ...initialPersonViewState,
  setLoading: (val: boolean) => set(() => ({ loading: val })),
  setFirstName: (val: string) => set({ firstName: val }),
  setMiddleNames: (val: string) => set({ middleNames: val }),
  setLastName: (val: string) => set({ lastName: val }),
  setDateOfBirth: (val: string) => set({ dateOfBirth: val }),
  setNationality: (val: string) => set({ nationality: val }),
  setInfluentialPerson: (val: InfluentialPersonStatus | string) =>
    set({ influentialPerson: val }),
  setIdentificationType: (val: IdentificationType | string) =>
    set({ identificationType: val }),
  setIdentificationNumber: (val: string) => set({ identificationNumber: val }),
  setIdentificationNumberExpiry: (val: string) =>
    set({ identificationNumberExpiry: val }),
  setContactDetails: (val: PersonContactDetails) =>
    set({ contactDetails: val }),
  setResidentialAddress: (val: Address) => set({ residentialAddress: val }),
  setSourceOfWealth: (val: SourceOfIncomeAndWealthType | "") =>
    set({ sourceOfWealth: val }),
  setSourceOfIncome: (val: SourceOfIncomeAndWealthType | "") =>
    set({ sourceOfIncome: val }),
  setFromState: (val: Person) =>
    set(() => ({
      firstName: val.firstName,
      middleNames: val.middleNames,
      lastName: val.lastName,
      dateOfBirth: val.dateOfBirth,
      nationality: val.nationality,
      influentialPerson: val.influentialPerson,
      identificationType: val.identificationType,
      identificationNumber: val.identificationNumber,
      identificationNumberExpiry: val.identificationNumberExpiry,
      contactDetails: val.contactDetails,
      residentialAddress: val.residentialAddress,
      sourceOfWealth: val.sourceOfWealth,
      sourceOfIncome: val.sourceOfIncome,
    })),
  setCachedPerson: (val: Person) => set({ cachedPerson: val }),
  setLastUpdateDate: (val: string) => set({ lastUpdateDate: val }),
  setLastUpdateBy: (val: string) => set({ lastUpdateBy: val }),
  getPersonObject: () => {
    const p = get();
    return new Person({
      ...p.cachedPerson,
      firstName: p.firstName,
      middleNames: p.middleNames,
      lastName: p.lastName,
      dateOfBirth: p.dateOfBirth,
      nationality: p.nationality,
      influentialPerson: p.influentialPerson,
      identificationType: p.identificationType,
      identificationNumber: p.identificationNumber,
      identificationNumberExpiry: p.identificationNumberExpiry,
      contactDetails: p.contactDetails,
      residentialAddress: p.residentialAddress,
      sourceOfWealth: p.sourceOfWealth,
      sourceOfIncome: p.sourceOfIncome,
    });
  },
  clear: () => set(() => initialPersonViewState),
}));
