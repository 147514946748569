import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Person } from "@mesh/common-js/dist/legal/person_pb";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useAPIContext } from "context/API";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import {
  GetPersonRequest,
  ListPersonsRequest,
} from "@mesh/common-js/dist/legal/personReadService_pb";
import { useFirebaseContext } from "context/Firebase";
import { UpdatePersonRequest } from "@mesh/common-js/src/legal/personWriteService_pb";

export type PersonContextType = {
  apiCallInProgress: boolean;

  myPerson: Person;
  setMyPerson: (person: Person) => void;

  personLoaded: boolean;
  personLoadError: string | null;
  clearPersonLoadError: () => void;
  refreshMyPerson: () => void;

  updatePerson: (person: Person, updateFields: string[]) => Promise<Person>;
  retrievePerson: () => Promise<Person>;
  listPersons: () => Promise<Person[]>;
};

export const defaultContext: PersonContextType = {
  apiCallInProgress: false,

  myPerson: new Person(),
  setMyPerson: () => null,

  personLoaded: false,
  personLoadError: null,
  clearPersonLoadError: () => null,
  refreshMyPerson: () => null,

  updatePerson: () => new Promise(() => null),
  retrievePerson: () => new Promise(() => Person),
  listPersons: () => new Promise(() => [Person]),
};

const Context = createContext<PersonContextType>(defaultContext);

export const PersonContext: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const {
    person: { readService, writeService },
  } = useAPIContext();
  const { authContext, userAuthenticated } = useApplicationContext();
  const { firebaseAuthenticated } = useFirebaseContext();
  const [myPerson, setMyPerson] = useState<Person>(new Person());
  const [personLoaded, setPersonLoaded] = useState<boolean>(false);
  const [fetchingPerson, setFetchingPerson] = useState<boolean>(false);
  const [updatingPerson, setUpdatingPerson] = useState<boolean>(false);
  const refreshMyPerson = () => setPersonLoaded(false);
  const [personLoadError, setPersonLoadError] = useState<string | null>(null);
  const clearPersonLoadError = () => setPersonLoadError(null);
  const fieldMask = new FieldMask();
  const loggedIn = firebaseAuthenticated && userAuthenticated;

  useEffect(() => {
    if (!loggedIn) {
      return;
    }
    (async () => {
      if (personLoaded || personLoadError) {
        return;
      }

      setFetchingPerson(true);
      let personResponse: Person | undefined;
      try {
        personResponse = await readService.getPerson(
          new GetPersonRequest().setContext(authContext.toFuture()),
        );
      } catch (e) {
        setPersonLoadError("Could not fetch person");
        setPersonLoaded(false);
        setFetchingPerson(false);
      }

      if (personResponse) {
        setMyPerson(personResponse);
        setPersonLoaded(true);
      }
      setFetchingPerson(false);
    })();
  }, [personLoadError, personLoaded, userAuthenticated, loggedIn]);

  const updatePerson = async (person: Person, updateFields: string[]) => {
    if (!person) {
      return myPerson;
    }

    setUpdatingPerson(true);
    let updatedPerson: Person | undefined;
    try {
      updatedPerson = await writeService.updatePerson(
        new UpdatePersonRequest()
          .setContext(authContext.toFuture())
          .setPerson(person)
          .setUpdateMask(fieldMask.setPathsList(updateFields)),
      );
      enqueueSnackbar("Identity Verification Info Saved", {
        variant: "success",
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Failed to save your personal information.: ${
          e.message.split(": ")[1]
            ? e.message.split(": ")[1]
            : err.message
              ? err.message
              : err.toString()
        }`,
        { variant: "error" },
      );
      return myPerson;
    }

    setUpdatingPerson(false);
    if (updatedPerson) {
      setMyPerson(updatedPerson);
      return updatedPerson;
    }

    return myPerson;
  };

  const retrievePerson = async () => {
    setFetchingPerson(true);
    let personResponse: Person | undefined;
    try {
      personResponse = await readService.getPerson(
        new GetPersonRequest().setContext(authContext.toFuture()),
      );
      return personResponse;
    } catch (e) {
      setPersonLoadError("Could not fetch person");
      setPersonLoaded(false);
      setFetchingPerson(false);
      return new Person();
    }
  };

  const listPersons = async () => {
    setFetchingPerson(true);
    let personResponse: Person[];
    try {
      personResponse = (
        await readService.listPersons(
          new ListPersonsRequest().setContext(authContext.toFuture()),
        )
      ).getRecordsList();
      setFetchingPerson(false);
      return personResponse;
    } catch (e) {
      setPersonLoadError("Could not list persons");
      setPersonLoaded(false);
      return [];
    }
  };

  return (
    <Context.Provider
      value={{
        apiCallInProgress: fetchingPerson || updatingPerson,

        myPerson,
        setMyPerson,

        personLoaded,
        personLoadError,
        clearPersonLoadError,
        refreshMyPerson,

        updatePerson,
        retrievePerson,
        listPersons: listPersons,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const usePersonContext = () => useContext(Context);
export { usePersonContext };
