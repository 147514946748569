import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import meshLogo from "assets/images/logo/meshLogo.svg";
import React, { useEffect, useState } from "react";
import { UserManagerRead, UserProfile as UserProfileClass } from "james/user";
import { GroupRoles } from "james/user/GroupRoles";
import { useSnackbar } from "notistack";
import backgroundCroppedDark from "assets/images/background/backgroundCropped.jpeg";
import { useWidth } from "utilities/general";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { useApplicationContext } from "context/Application/Application";
import { UserProfile } from "./components/UserProfile/UserProfile";
import { GuidesController } from "james/user/GuidesController";
import { useErrorContext } from "context/Error";
import { useNavigate } from "react-router-dom";
import config from "react-global-configuration";

const ViewSteps = {
  userProfile: 0,
  userGroups: 1,
  loadingScreen: 2,
};

export function FirstLoginSteps() {
  const { errorContextErrorTranslator } = useErrorContext();
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const isDesktop = isWidthUp("sm", width);
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [firstStepsStep, setFirstStepsStep] = useState(ViewSteps.userProfile);
  const [userFirstName, setUserFirstName] = useState("");
  const { refreshLoginClaims } = useApplicationContext();
  const navigate = useNavigate();

  const handleCompleteFirstLoginSteps = async () => {
    setFirstStepsStep(ViewSteps.loadingScreen);
    try {
      await GuidesController.CompleteFirstTimeLoginSteps({
        context: authContext,
      });
      await refreshLoginClaims();
      if (config.get("allowMFAVerification")) {
        navigate("/verify-mobile-number");
      } else {
        navigate("/market");
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Completing Profile: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      console.error(
        `error completing profile: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }
  };

  return (
    <>
      {isDesktop && (
        <Box
          sx={{
            height: "inherit",
            width: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "hidden",
            backgroundImage: `url(${backgroundCroppedDark})`,
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: "inherit",
            }}
          >
            <Box
              sx={{
                height: "150px",
              }}
            >
              <img alt="" width="300" src={meshLogo} />
            </Box>
            {(() => {
              switch (firstStepsStep) {
                case ViewSteps.userProfile:
                  return (
                    <UserProfile
                      onSubmitClick={(param) => {
                        setUserFirstName(param.firstName);
                        setFirstStepsStep(ViewSteps.userGroups);
                      }}
                    />
                  );

                case ViewSteps.userGroups:
                  return (
                    <UserGroupRolesScreen
                      firstName={userFirstName}
                      onNextClick={handleCompleteFirstLoginSteps}
                    />
                  );

                case ViewSteps.loadingScreen:
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        height: "70%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  );
                default:
                  return null;
              }
            })()}
          </Box>
        </Box>
      )}
      {isMobile && (
        <Box>
          {(() => {
            switch (firstStepsStep) {
              case ViewSteps.userProfile:
                return (
                  <UserProfile
                    onSubmitClick={(param) => {
                      setUserFirstName(param.firstName);
                      setFirstStepsStep(ViewSteps.userGroups);
                    }}
                  />
                );

              case ViewSteps.userGroups:
                return (
                  <UserGroupRolesScreen
                    firstName={userFirstName}
                    onNextClick={handleCompleteFirstLoginSteps}
                  />
                );

              case ViewSteps.loadingScreen:
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "70%",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                );
              default:
                return null;
            }
          })()}
        </Box>
      )}
    </>
  );
}

interface UserGroupRolesScreenProps {
  firstName: string;
  onNextClick: () => void;
}

function UserGroupRolesScreen(props: UserGroupRolesScreenProps) {
  const [userProfile, setUserProfile] = useState<undefined | UserProfileClass>(
    undefined,
  );
  const { authContext } = useApplicationContext();

  useEffect(() => {
    UserManagerRead.RetrieveMyProfile({
      context: authContext,
    }).then((response) => setUserProfile(response.profile));
  }, []);

  if (!userProfile) {
    return (
      <Backdrop
        sx={() => ({ zIndex: (theme) => theme.zIndex.drawer + 1 })}
        open
      >
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: "repeat(5,auto)",
          textAlign: "center",
          p: 3,
        }}
      >
        <Typography component="p" noWrap={false} variant="h1">
          Hello {props.firstName}. Welcome&nbsp;to&nbsp;Mesh.
        </Typography>
        <Typography
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
          })}
          color="textSecondary"
        >
          Now get to know the group(s) and role(s) you&apos;ve been assigned to.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          color="textSecondary"
        >
          A group refers to a collection of people working together, such
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          color="textSecondary"
          variant="body1"
        >
          as divisions, departments, teams, etc.
        </Typography>
        <Typography
          variant="h4"
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            fontWeight: theme.typography.fontWeightBold,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
          })}
        >
          You&apos;ve been added to the following groups
        </Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            margin: theme.spacing(1),
            overflowY: "auto",
            marginBottom: theme.spacing(3),
            justifyContent: "center",
          })}
          className={"meshScroll"}
        >
          {new UserProfileClass(userProfile).groupRoles.map((v, i) => (
            <GroupRolesCard key={i} groupRoles={v} />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "161px",
              height: "36px",
            }}
            id="companyUserFirstSteps-next-button"
            onClick={props.onNextClick}
            variant="contained"
            color="primary"
            size="large"
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

interface GroupRolesCardProps {
  groupRoles: GroupRoles;
}

function GroupRolesCard(props: GroupRolesCardProps) {
  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "auto auto",
        borderRadius: "10px",
        alignItems: "center",
        width: {
          sm: "550px",
        },
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        margin: theme.spacing(1),
      })}
    >
      <Typography
        sx={(theme) => ({
          marginTop: "4px",
          marginRight: theme.spacing(4),
        })}
        align="left"
        variant="h6"
      >
        {props.groupRoles.groupName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {props.groupRoles.roleNames.map((v, i) => (
          <Typography variant="body1" key={i}>
            {`${v}${i !== props.groupRoles.roleNames.length - 1 ? "," : ""}`}
            &nbsp;
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
