import { useCallback } from "react";
import {
  AccountDetails,
  AssetDetailViewCommerceDetails,
  ErrorDetails,
  KYCSubmittedDetails,
  PageDetails,
  TransactionDetails,
  TutorialDetails,
  UserDetails,
} from "types/gtm";
import { hash } from "utilities/hash/hash";
import { userAffiliations, userCategories, userLoginTypes } from "const/gtm";
import { mapToStandardCurrency } from "./functions";
import { useUserContext } from "context/User/UserService";
import { userToPast } from "../../james/user/User";

export const useGTMTriggersPusher = () => {
  const { myUser } = useUserContext();

  const pushLoginCompletedEvent = useCallback((user?: UserDetails) => {
    if (user) {
      // clean up the properties from the UserDetails
      window.mtDataLayer.user = {
        user_id: user.user_id.trim().toLowerCase(),
        hashed_email: user.hashed_email.trim().toLowerCase(),
        user_category: user.user_category,
        user_kyc_status: user.user_kyc_status.trim().toLowerCase(),
        user_kyc_request_date: user.user_kyc_request_date,
        user_kyc_approved_date: user.user_kyc_approved_date,
        user_login_type: user.user_login_type,
        user_primary_affiliation: user.user_primary_affiliation,
      } as UserDetails;
    }

    window.dataLayer.push({ event: "loginComplete" });
  }, []);

  const pushPageViewEvent = useCallback((page?: PageDetails) => {
    if (page) {
      // clean up the properties from the PageDetails
      window.mtDataLayer.page = {
        page_name: page.page_name.trim().toLowerCase(),
        page_device_type: page.page_device_type,
        page_section: page.page_section.trim().toLowerCase(),
        page_type: page.page_type.trim().toLowerCase(),
        page_url: page.page_url.trim().toLowerCase(),
      } as PageDetails;
      window.mtDataLayer.page = page;
    }
    window.dataLayer.push({ event: "virtualPageview" });
  }, []);

  const pushSignUpCompletedEvent = useCallback((user?: UserDetails) => {
    if (user) {
      // clean up the properties from the UserDetails
      window.mtDataLayer.user = {
        user_id: user.user_id.trim().toLowerCase(),
        hashed_email: user.hashed_email.trim().toLowerCase(),
        user_category: user.user_category,
        user_kyc_status: user.user_kyc_status.trim().toLowerCase(),
        user_kyc_request_date: user.user_kyc_request_date,
        user_login_type: user.user_login_type,
        user_primary_affiliation: user.user_primary_affiliation,
      } as UserDetails;
    }

    window.dataLayer.push({ event: "signUpComplete" });
    return undefined;
  }, []);

  const pushDefundAccountComplete = useCallback((account?: AccountDetails) => {
    if (account) {
      // clean up the properties from the UserDetails
      window.mtDataLayer.account = {
        account_no: account.account_no,
        account_transaction_amount: account.account_transaction_amount,
        account_transaction_type: account.account_transaction_type,
      } as AccountDetails;
    }

    window.dataLayer.push({ event: "defundAccountComplete" });
    return undefined;
  }, []);

  const pushTransferComplete = useCallback((account?: AccountDetails) => {
    if (account) {
      // clean up the properties from the UserDetails
      window.mtDataLayer.account = {
        account_no: account.account_no,
        account_transaction_amount: account.account_transaction_amount,
        account_transaction_type: account.account_transaction_type,
      } as AccountDetails;
    }

    window.dataLayer.push({ event: "fundsTransferComplete" });
    return undefined;
  }, []);

  const pushFundAccountComplete = useCallback((account?: AccountDetails) => {
    if (account) {
      window.mtDataLayer.account = {
        account_no: account.account_no,
        account_transaction_amount: account.account_transaction_amount,
        account_transaction_type: account.account_transaction_type,
      } as AccountDetails;
    }

    window.dataLayer.push({ event: "fundAccountComplete" });
    return undefined;
  }, []);

  const pushTransactionStart = useCallback(
    (transactionDetails: TransactionDetails) => {
      window.mtDataLayer.transaction = {
        transaction_id: transactionDetails.transaction_id.toLowerCase(),
        transaction_type: transactionDetails.transaction_type.toLowerCase(),
        transaction_asset_sell_price:
          transactionDetails.transaction_asset_sell_price.toLowerCase(),
        transaction_asset_buy_price:
          transactionDetails.transaction_asset_buy_price.toLowerCase(),
        transaction_asset_name:
          transactionDetails.transaction_asset_name.toLowerCase(),
        transaction_asset_id:
          transactionDetails.transaction_asset_id.toLowerCase(),
        transaction_asset_type:
          transactionDetails.transaction_asset_type.toLowerCase(),
        transaction_asset_issuer:
          transactionDetails.transaction_asset_issuer.toLowerCase(),
        transaction_asset_risk_rating:
          transactionDetails.transaction_asset_risk_rating.toLowerCase(),
        transaction_asset_investor_profile:
          transactionDetails.transaction_asset_investor_profile.toLowerCase(),
        transaction_date: transactionDetails.transaction_date.toLowerCase(),
        transaction_currency: mapToStandardCurrency(
          transactionDetails.transaction_currency,
        ),
        transaction_investment_amount:
          transactionDetails.transaction_investment_amount.toLowerCase(),
        transaction_slippage:
          transactionDetails.transaction_slippage.toLowerCase(),
        transaction_stage: transactionDetails.transaction_stage.toLowerCase(),
        transaction_trade_fee:
          transactionDetails.transaction_trade_fee.toLowerCase(),
      } as TransactionDetails;
      window.dataLayer.push({ event: "transactionStart" });

      // Begin checkout - ecommerce
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: mapToStandardCurrency(
            transactionDetails.transaction_currency,
          ),
          value: transactionDetails.transaction_investment_amount,
          items: [
            {
              item_id: transactionDetails.transaction_asset_id.toLowerCase(),
              item_name:
                transactionDetails.transaction_asset_name.toLowerCase(),
              affiliation:
                window.mtDataLayer.platform.platform_name.toLowerCase(),
              index: 0,
              item_brand:
                transactionDetails.transaction_asset_issuer.toLowerCase(),
              item_category:
                "asset type:" +
                transactionDetails.transaction_asset_type.toLowerCase(),
              item_category2:
                "asset risk rating:" +
                transactionDetails.transaction_asset_risk_rating.toLowerCase(),
              item_category3:
                "asset investor profile:" +
                transactionDetails.transaction_asset_investor_profile.toLowerCase(),
              item_list_id: transactionDetails.transaction_asset_id,
              item_list_name: transactionDetails.transaction_asset_name,
              price: transactionDetails.transaction_asset_buy_price,
              quantity: 1,
            },
          ],
        },
      });
      return undefined;
    },
    [],
  );

  const pushTransactionComplete = useCallback(
    (transactionDetails: TransactionDetails) => {
      window.mtDataLayer.transaction = {
        transaction_id: transactionDetails.transaction_id.toLowerCase(),

        transaction_type: transactionDetails.transaction_type.toLowerCase(),

        transaction_asset_sell_price:
          transactionDetails.transaction_asset_sell_price.toLowerCase(),

        transaction_asset_buy_price:
          transactionDetails.transaction_asset_buy_price.toLowerCase(),

        transaction_asset_name:
          transactionDetails.transaction_asset_name.toLowerCase(),

        transaction_asset_id:
          transactionDetails.transaction_asset_id.toLowerCase(),

        transaction_asset_type:
          transactionDetails.transaction_asset_type.toLowerCase(),

        transaction_asset_issuer:
          transactionDetails.transaction_asset_issuer.toLowerCase(),

        transaction_asset_risk_rating:
          transactionDetails.transaction_asset_risk_rating.toLowerCase(),

        transaction_asset_investor_profile:
          transactionDetails.transaction_asset_investor_profile.toLowerCase(),

        transaction_currency: mapToStandardCurrency(
          transactionDetails.transaction_currency,
        ),

        transaction_investment_amount:
          transactionDetails.transaction_investment_amount.toLowerCase(),

        transaction_stage: transactionDetails.transaction_stage.toLowerCase(),

        transaction_date: transactionDetails.transaction_date,

        transaction_slippage: transactionDetails.transaction_slippage,

        transaction_trade_fee: transactionDetails.transaction_trade_fee,
      } as TransactionDetails;
      window.dataLayer.push({ event: "transactionComplete" });
      // Track purchase - eCommerce
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: transactionDetails.transaction_id,
          value: transactionDetails.transaction_investment_amount,
          currency: mapToStandardCurrency(
            transactionDetails.transaction_currency,
          ),
          items: [
            {
              item_id: transactionDetails.transaction_asset_id.toLowerCase(),
              item_name:
                transactionDetails.transaction_asset_name.toLowerCase(),
              affiliation:
                window.mtDataLayer.platform.platform_name.toLowerCase(),
              index: 0,
              item_brand:
                transactionDetails.transaction_asset_issuer.toLowerCase(),
              item_category:
                "asset type:" +
                transactionDetails.transaction_asset_type.toLowerCase(),
              item_category2:
                "asset risk rating:" +
                transactionDetails.transaction_asset_risk_rating.toLowerCase(),
              item_category3:
                "asset investor profile:" +
                transactionDetails.transaction_asset_investor_profile.toLowerCase(),
              item_list_id: transactionDetails.transaction_asset_id,
              item_list_name: transactionDetails.transaction_asset_name,
              price: transactionDetails.transaction_asset_buy_price,
              quantity: 1,
            },
          ],
        },
      });

      return undefined;
    },
    [],
  );
  const pushTransactionAbandon = useCallback(
    (transactionDetails: TransactionDetails) => {
      window.mtDataLayer.transaction = {
        transaction_id: transactionDetails.transaction_id.toLowerCase(),
        transaction_type: transactionDetails.transaction_type.toLowerCase(),
        transaction_asset_sell_price:
          transactionDetails.transaction_asset_sell_price.toLowerCase(),
        transaction_asset_buy_price:
          transactionDetails.transaction_asset_buy_price.toLowerCase(),
        transaction_asset_name:
          transactionDetails.transaction_asset_name.toLowerCase(),
        transaction_asset_id:
          transactionDetails.transaction_asset_id.toLowerCase(),
        transaction_asset_type:
          transactionDetails.transaction_asset_type.toLowerCase(),
        transaction_asset_issuer:
          transactionDetails.transaction_asset_issuer.toLowerCase(),
        transaction_asset_risk_rating:
          transactionDetails.transaction_asset_risk_rating.toLowerCase(),
        transaction_asset_investor_profile:
          transactionDetails.transaction_asset_investor_profile.toLowerCase(),
        transaction_date: transactionDetails.transaction_date.toLowerCase(),
        transaction_currency: mapToStandardCurrency(
          transactionDetails.transaction_currency,
        ),
        transaction_investment_amount:
          transactionDetails.transaction_investment_amount.toLowerCase(),
        transaction_slippage:
          transactionDetails.transaction_slippage.toLowerCase(),
        transaction_stage: transactionDetails.transaction_stage.toLowerCase(),
        transaction_trade_fee:
          transactionDetails.transaction_trade_fee.toLowerCase(),
      } as TransactionDetails;

      window.dataLayer.push({ event: "transactionAbandon" });
      return undefined;
    },
    [],
  );

  const pushPlatformError = useCallback((error?: ErrorDetails) => {
    if (error) {
      window.mtDataLayer.error = {
        error_message: error.error_message,
        error_type: error.error_type,
        error_url: error.error_url,
      };
    }
    window.dataLayer.push({ event: "platformError" });
    return undefined;
  }, []);

  const pushKYCSubmittedEvent = useCallback(
    (kycSubmittedDetail: KYCSubmittedDetails) => {
      // clean up the properties from the UserDetails
      if (myUser) {
        window.mtDataLayer.user = {
          user_id: userToPast(myUser).id.trim().toLowerCase(),
          hashed_email: hash(userToPast(myUser).email).trim().toLowerCase(),
          user_category: userCategories.active,
          user_kyc_status: kycSubmittedDetail.user_kyc_status,
          user_kyc_request_date: kycSubmittedDetail.user_kyc_request_date,
          user_kyc_approved_date: "",
          user_login_type: userLoginTypes.loggedIn,
          user_primary_affiliation: userAffiliations.internal,
        };
      }

      window.dataLayer.push({ event: "kycStart" });
    },
    [myUser],
  );

  const pushTutorialStartedEvent = useCallback(
    (tutorialDetails: TutorialDetails) => {
      if (tutorialDetails) {
        window.mtDataLayer.tutorial = {
          tutorial_start: true,
          tutorial_complete: false,
          tutorial_name: tutorialDetails.tutorial_name,
          tutorial_type: tutorialDetails.tutorial_type,
        };

        window.dataLayer.push({ event: "tutorialStart" });
      }
    },
    [],
  );

  const pushTutorialCompletedEvent = useCallback(
    (tutorialDetails: TutorialDetails) => {
      if (tutorialDetails) {
        window.mtDataLayer.tutorial = {
          tutorial_start: false,
          tutorial_complete: true,
          tutorial_name: tutorialDetails.tutorial_name,
          tutorial_type: tutorialDetails.tutorial_type,
        };

        window.dataLayer.push({ event: "tutorialComplete" });
      }
    },
    [],
  );

  const pushAssetDetailViewEvent = useCallback(
    (assetDetailViewObj: AssetDetailViewCommerceDetails) => {
      window.mtDataLayer.transaction = {
        transaction_asset_sell_price:
          assetDetailViewObj.transaction_asset_sell_price.toLowerCase(),
        transaction_asset_buy_price:
          assetDetailViewObj.transaction_asset_buy_price.toLowerCase(),
        transaction_asset_name:
          assetDetailViewObj.transaction_asset_name.toLowerCase(),
        transaction_asset_id:
          assetDetailViewObj.transaction_asset_id.toLowerCase(),
        transaction_asset_type:
          assetDetailViewObj.transaction_asset_type.toLowerCase(),
        transaction_asset_issuer:
          assetDetailViewObj.transaction_asset_issuer.toLowerCase(),
        transaction_asset_risk_rating:
          assetDetailViewObj.transaction_asset_risk_rating.toLowerCase(),
        transaction_asset_investor_profile:
          assetDetailViewObj.transaction_asset_investor_profile.toLowerCase(),
        transaction_currency: mapToStandardCurrency(
          assetDetailViewObj.transaction_currency,
        ),
      };

      // Track view_item - eCommerce
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          currency: mapToStandardCurrency(
            assetDetailViewObj.transaction_currency,
          ),
          value: "0",
          items: [
            {
              item_id: assetDetailViewObj.transaction_asset_id.toLowerCase(),
              item_name:
                assetDetailViewObj.transaction_asset_name.toLowerCase(),
              affiliation:
                window.mtDataLayer.platform.platform_name.toLowerCase(),
              index: 0,
              item_brand:
                assetDetailViewObj.transaction_asset_issuer.toLowerCase(),
              item_category:
                "asset type:" +
                assetDetailViewObj.transaction_asset_type.toLowerCase(),
              item_category2:
                "asset risk rating:" +
                assetDetailViewObj.transaction_asset_risk_rating.toLowerCase(),
              item_category3:
                "asset investor profile:" +
                assetDetailViewObj.transaction_asset_investor_profile.toLowerCase(),
              item_list_id: assetDetailViewObj.transaction_asset_id,
              item_list_name: assetDetailViewObj.transaction_asset_name,
              price: assetDetailViewObj.transaction_asset_buy_price,
              quantity: 1,
            },
          ],
        },
      });
      return;
    },
    [],
  );

  return {
    pushTutorialStartedEvent,
    pushTutorialCompletedEvent,
    pushTransferComplete,
    pushDefundAccountComplete,
    pushFundAccountComplete,
    pushTransactionStart,
    pushTransactionComplete,
    pushLoginCompletedEvent,
    pushPageViewEvent,
    pushSignUpCompletedEvent,
    pushPlatformError,
    pushKYCSubmittedEvent,
    pushTransactionAbandon,
    pushAssetDetailViewEvent,
  };
};
