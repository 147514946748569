import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security/Context";

export const TOTPSenderServiceProviderName = "verification-SMSTOTPSender";

export interface SendSMSTOTPRequest {
  context: Context;
  phoneNumber: string;
}

export interface SendSMSTOTPResponse {
  expTime: string;
  expPeriod: string;
}

export const SMSTOTPSender = {
  async SendSMSTOTP(request: SendSMSTOTPRequest): Promise<SendSMSTOTPResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthenticatedRatedLimitedURL"),
      method: `${TOTPSenderServiceProviderName}.SendSMSTOTP`,
      request,
    });
    return {
      expTime: response.expTime,
      expPeriod: response.expPeriod,
    };
  },
};
