import BigNumber from "bignumber.js";
import { ValidationResult } from "common/validation";
import { Mechanism, MechanismType } from "james/market";

export function validateDirectOrderMarketMechanism(
  marketMechanism: Mechanism,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[`${MechanismType.DirectOrder}-${field}`] =
      message;
  };

  // confirm only 1 quote parameter for direct order
  if (marketMechanism.quoteParameters.length !== 1) {
    invalid(
      "quoteParameters",
      "there should always be exactly 1 quote parameter for direct orders",
    );
    return validationResult;
  }

  // get quote paramater to perform validations
  const quoteParamater = marketMechanism.quoteParameters[0];

  if (quoteParamater.minimumDealSize.value.isLessThan(BigNumber("0.00002"))) {
    invalid("minimumDealSize", "Must be >= 0.00002");
  }

  if (
    !quoteParamater.maximumDealSize.value.isGreaterThan(
      quoteParamater.minimumDealSize.value,
    )
  ) {
    invalid("maximumDealSize", "Must be > Minimum");
  }

  return validationResult;
}
