import { Person, SourceOfIncomeAndWealthType } from "james/legal/person";
import { IdentificationType } from "james/legal";
import { ValidationResult } from "common/validation";
import { LuhnAlgorithm } from "utilities/validation";
import { countries } from "james/country";
import dayjs from "dayjs";
import {
  KYCDocument,
  KYCDocumentState,
  KYCDocumentType,
} from "james/kyc/KYCDocument";
import { IDNumberToDOBConverter } from "./Functions";
import { InfluentialPersonStatus } from "james/legal/person/Person";
import { Address } from "james/location/Address";

export type FormState = {
  person: Person;
  clientKYCDocuments: {
    identificationClassDocument: KYCDocument[];
    proofOfResidenceClassDocument: KYCDocument[];
  };
};

export type FormUpdaterSpecsType = {
  // replace entire person
  person: (value: Person, prevState?: FormState) => FormState;
  // person fields
  firstName: (value: string, prevState?: FormState) => FormState;
  lastName: (value: string, prevState?: FormState) => FormState;
  middleNames: (value: string, prevState?: FormState) => FormState;
  dateOfBirth: (value: string, prevState?: FormState) => FormState;
  nationality: (value: string, prevState?: FormState) => FormState;
  influentialPerson: (
    value: InfluentialPersonStatus | "",
    prevState?: FormState,
  ) => FormState;
  identificationType: (
    value: IdentificationType | "",
    prevState?: FormState,
  ) => FormState;
  sourceOfWealth: (
    value: SourceOfIncomeAndWealthType | "",
    prevState?: FormState,
  ) => FormState;
  sourceOfIncome: (
    value: SourceOfIncomeAndWealthType | "",
    prevState?: FormState,
  ) => FormState;
  identificationNumber: (value: string, prevState?: FormState) => FormState;
  identificationNumberExpiry: (
    value: string,
    prevState?: FormState,
  ) => FormState;
  addressLine1: (value: string, prevState?: FormState) => FormState;
  addressLine2: (value: string, prevState?: FormState) => FormState;
  suburb: (value: string, prevState?: FormState) => FormState;
  postalCode: (value: string, prevState?: FormState) => FormState;
  countryCode: (value: string, prevState?: FormState) => FormState;
  province: (value: string, prevState?: FormState) => FormState;
  city: (value: string, prevState?: FormState) => FormState;
  // client documents
  identificationClassDocument: (
    value: KYCDocument[],
    prevState?: FormState,
  ) => FormState;
  proofOfResidenceClassDocument: (
    value: KYCDocument[],
    prevState?: FormState,
  ) => FormState;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  person: (
    value: Person,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: new Person(value),
  }),
  firstName: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      firstName: value,
    },
  }),
  lastName: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      lastName: value,
    },
  }),
  middleNames: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      middleNames: value,
    },
  }),
  dateOfBirth: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      dateOfBirth: value,
    },
  }),
  sourceOfWealth: (
    value: SourceOfIncomeAndWealthType | "",
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      sourceOfWealth: value,
    },
  }),
  sourceOfIncome: (
    value: SourceOfIncomeAndWealthType | "",
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      sourceOfIncome: value,
    },
  }),
  nationality: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      nationality: value,
    },
  }),
  identificationType: (
    value: IdentificationType | "",
    prevState: FormState = {} as FormState,
  ): FormState => {
    if (
      value === IdentificationType.SouthAfricanIDIdentificationType &&
      prevState.person.identificationNumber
    ) {
      // when changing identification type if ID Number is true
      // and if SouthAfricanIDIdentificationType update Identification type
      // and DOB else update Identification type only
      // Also clear identificationNumberExpiry field since passport type is not used
      return {
        ...prevState,
        person: {
          ...prevState.person,
          identificationType: value,
          identificationNumberExpiry: "",
          dateOfBirth: IDNumberToDOBConverter(
            prevState.person.identificationNumber,
          ),
        },
      };
    } else {
      return {
        ...prevState,
        person: {
          ...prevState.person,
          identificationType: value,
        },
      };
    }
  },
  identificationNumber: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => {
    //if not PassportIdentificationType update DOB
    // and ID Number else update ID Number
    return {
      ...prevState,
      person: {
        ...prevState.person,
        identificationNumber: value,
        dateOfBirth: IDNumberToDOBConverter(value),
      },
    };
  },
  identificationNumberExpiry: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      identificationNumberExpiry: value,
    },
  }),
  influentialPerson: (
    value: InfluentialPersonStatus | "",
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      influentialPerson: value,
    },
  }),
  addressLine1: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        addressLine1: value,
      }),
    },
  }),
  addressLine2: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        addressLine2: value,
      }),
    },
  }),
  suburb: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        suburb: value,
      }),
    },
  }),
  postalCode: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        postalCode: value,
      }),
    },
  }),
  countryCode: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        countryCode: value,
      }),
    },
  }),
  province: (
    value: string,
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        province: value,
      }),
    },
  }),
  city: (value: string, prevState: FormState = {} as FormState): FormState => ({
    ...prevState,
    person: {
      ...prevState.person,
      residentialAddress: new Address({
        ...prevState.person.residentialAddress,
        city: value,
      }),
    },
  }),
  identificationClassDocument: (
    value: KYCDocument[],
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    clientKYCDocuments: {
      ...prevState.clientKYCDocuments,
      identificationClassDocument: value,
    },
  }),
  proofOfResidenceClassDocument: (
    value: KYCDocument[],
    prevState: FormState = {} as FormState,
  ): FormState => ({
    ...prevState,
    clientKYCDocuments: {
      ...prevState.clientKYCDocuments,
      proofOfResidenceClassDocument: value,
    },
  }),
};

export const validationFunc = async (
  formState: FormState,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!formState.person.influentialPerson) {
    newValidationState.fieldValidations.influentialPerson =
      "Please make a declaration";
  }

  if (!formState.person.sourceOfIncome) {
    newValidationState.fieldValidations.sourceOfIncome =
      "Please select a source of income";
  }

  if (!formState.person.sourceOfWealth) {
    newValidationState.fieldValidations.sourceOfWealth =
      "Please select a source of wealth";
  }

  if (!formState.person.nationality) {
    newValidationState.fieldValidations.nationality =
      "Please select a nationality";
  }

  if (!formState.person.identificationType) {
    newValidationState.fieldValidations.identificationType =
      "Please select an identification type";
  }

  if (!formState.person.firstName) {
    newValidationState.fieldValidations.firstName = "Please enter first name";
  }
  if (!formState.person.lastName) {
    newValidationState.fieldValidations.lastName = "Please enter last name";
  }

  if (!formState.person.identificationNumber) {
    newValidationState.fieldValidations.identificationNumber =
      "Please enter identification number";
  }

  if (
    formState.person.identificationType ===
      IdentificationType.SouthAfricanIDIdentificationType &&
    (formState.person.identificationNumber.length !== 13 ||
      !LuhnAlgorithm(formState.person.identificationNumber))
  ) {
    newValidationState.fieldValidations.identificationNumber =
      "Please provide a valid South African ID number";
  }

  if (formState.person.dateOfBirth === "") {
    newValidationState.fieldValidations.dateOfBirth =
      "Please enter a date of birth";
  } else if (
    formState.person.identificationNumber.length === 13 &&
    formState.person.identificationType !==
      IdentificationType.PassportIdentificationType &&
    dayjs(formState.person.dateOfBirth).format() !==
      dayjs(
        IDNumberToDOBConverter(formState.person.identificationNumber),
      ).format()
  ) {
    newValidationState.fieldValidations.dateOfBirth =
      "Date of birth must correspond with ID number";
  }

  if (formState.person.dateOfBirth === "Invalid date") {
    newValidationState.fieldValidations.dateOfBirth =
      "Please enter a valid date of birth";
  }

  // find identification document classification
  if (formState.clientKYCDocuments.identificationClassDocument.length === 0) {
    newValidationState.fieldValidations.identificationClassDocument =
      "No identification documents uploaded";
  }

  if (formState.clientKYCDocuments.proofOfResidenceClassDocument.length === 0) {
    newValidationState.fieldValidations.proofOfResidenceClassDocument =
      "No proof of residence documents uploaded";
  }

  // iterate through the proof of residence documents to find document that are not uploaded
  formState.clientKYCDocuments.proofOfResidenceClassDocument.every((d) => {
    if (d.state !== KYCDocumentState.Uploaded) {
      newValidationState.fieldValidations.proofOfResidenceClassDocument = `${d.type} is not uploaded`;
      return false;
    }
    return true;
  });

  formState.clientKYCDocuments.identificationClassDocument.every((d) => {
    if (d.state !== KYCDocumentState.Uploaded) {
      newValidationState.fieldValidations.identificationClassDocument = `${d.type} is not uploaded`;
      return false;
    }
    return true;
  });

  if (formState.clientKYCDocuments.identificationClassDocument.length === 1) {
    if (
      formState.clientKYCDocuments.identificationClassDocument[0].type ===
        KYCDocumentType.SouthAfricanIDCardFrontType &&
      formState.clientKYCDocuments.identificationClassDocument[0].state ===
        KYCDocumentState.Uploaded
    ) {
      newValidationState.fieldValidations.identificationClassDocument = `${KYCDocumentType.SouthAfricanIDCardBackType} is not uploaded`;
    }

    if (
      formState.clientKYCDocuments.identificationClassDocument[0].type ===
        KYCDocumentType.SouthAfricanIDCardBackType &&
      formState.clientKYCDocuments.identificationClassDocument[0].state ===
        KYCDocumentState.Uploaded
    ) {
      newValidationState.fieldValidations.identificationClassDocument = `${KYCDocumentType.SouthAfricanIDCardFrontType} is not uploaded`;
    }

    if (
      formState.clientKYCDocuments.identificationClassDocument[0].type ===
        KYCDocumentType.SouthAfricanDriversLicenseFrontDocumentType &&
      formState.clientKYCDocuments.identificationClassDocument[0].state ===
        KYCDocumentState.Uploaded
    ) {
      newValidationState.fieldValidations.identificationClassDocument = `${KYCDocumentType.SouthAfricanDriversLicenseBackDocumentType} is not uploaded`;
    }

    if (
      formState.clientKYCDocuments.identificationClassDocument[0].type ===
        KYCDocumentType.SouthAfricanDriversLicenseBackDocumentType &&
      formState.clientKYCDocuments.identificationClassDocument[0].state ===
        KYCDocumentState.Uploaded
    ) {
      newValidationState.fieldValidations.identificationClassDocument = `${KYCDocumentType.SouthAfricanDriversLicenseFrontDocumentType} is not uploaded`;
    }
  }

  if (!formState.person.residentialAddress.addressLine1) {
    newValidationState.fieldValidations.addressLine1 =
      "Please enter address line 1";
  }

  if (!formState.person.residentialAddress.city) {
    newValidationState.fieldValidations.city = "Please enter city";
  }

  if (!formState.person.residentialAddress.province) {
    newValidationState.fieldValidations.province = "Please enter province";
  }

  if (!formState.person.residentialAddress.postalCode) {
    newValidationState.fieldValidations.postalCode = "Please enter postal code";
  }

  if (!formState.person.residentialAddress.countryCode) {
    newValidationState.fieldValidations.countryCode = "Please select a country";
  }

  // find proof of residence document classification
  if (formState.clientKYCDocuments.proofOfResidenceClassDocument.length === 0) {
    newValidationState.fieldValidations.proofOfResidenceClassDocument =
      "No document uploaded";
  }

  // set validation state to false if there's any keys on the validation state object
  if (Object.keys(newValidationState.fieldValidations).length !== 0) {
    newValidationState.valid = false;
  }

  return newValidationState;
};

const initializeState = (): FormState => {
  const person = new Person();
  person.nationality = countries[200].value;
  return {
    person,
    clientKYCDocuments: {
      identificationClassDocument: [],
      proofOfResidenceClassDocument: [],
    },
  };
};

export const initialState = initializeState();

export const checkCompletedSteps = ({
  touchedFields,
  validationResult,
  formState,
}: {
  touchedFields: Set<string>;
  validationResult: ValidationResult;
  formState: FormState;
}): {
  stepOneComplete: boolean;
  stepTwoComplete: boolean;
} => {
  const stepOneComplete = ((): boolean => {
    if (
      touchedFields.has("identificationNumber") &&
      formState.person.identificationType !==
        IdentificationType.PassportIdentificationType &&
      formState.person.identificationNumber.length >= 6
    ) {
      touchedFields.add("dateOfBirth");
    }

    if (
      formState.person.identificationType !==
      IdentificationType.PassportIdentificationType
    ) {
      touchedFields.add("identificationNumberExpiry");
    }

    touchedFields.add("nationality");

    const fields = [
      "firstName",
      "lastName",
      "dateOfBirth",
      "nationality",
      "identificationNumber",
      "sourceOfIncome",
      "sourceOfWealth",
      "identificationNumberExpiry",
      "identificationType",
      "influentialPerson",
    ];

    // all fields should have been touched
    const containsAll = fields.every((val) => touchedFields.has(val));

    // all fields should be valid
    const filteredValidationKeys = Object.keys(
      validationResult.fieldValidations,
    ).filter((key) => fields.includes(key));
    const noFieldErrs = filteredValidationKeys.every(
      (key) => validationResult.fieldValidations[key] === "" || undefined,
    );

    return containsAll && noFieldErrs;
  })();

  const stepTwoComplete = ((): boolean => {
    const fields = [
      "addressLine1",
      "postalCode",
      "city",
      "province",
      "countryCode",
    ];

    // all fields should have been touched
    const containsAll = fields.every((val) => touchedFields.has(val));

    // all fields should be valid
    const filteredValidationKeys = Object.keys(
      validationResult.fieldValidations,
    ).filter((key) => fields.includes(key));
    const noFieldErrs = filteredValidationKeys.every(
      (key) => validationResult.fieldValidations[key] === "" || undefined,
    );
    return containsAll && noFieldErrs;
  })();

  return {
    stepOneComplete,
    stepTwoComplete,
  };
};
