import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  RestartAlt as ReloadIcon,
} from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import {
  useOpenLatestAUSAgreement,
  useOpenLatestPaaSAgreement,
} from "james/compliance";
import { useNavigate } from "react-router-dom";
import { useValidatedForm } from "hooks/useForm";
import {
  FormState,
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  initialState,
  validationFunc,
} from "views/SetupClientProfile/useFormState";
import { UserManagerRead } from "james/user";
import { doUpload } from "utilities/network/upload";
import { ClientProfileAdministrator } from "james/onboarding/SetupIndividualClientProfile";
import { useSnackbar } from "notistack";
import { ProfilePictureController } from "james/user/ProfilePictureController";
import { useFirebaseContext } from "context/Firebase";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export function SetupIndividualClientProfileCard() {
  const navigate = useNavigate();
  const { openLatestPaaSAgreement } = useOpenLatestPaaSAgreement();
  const { openLatestAUSAgreement } = useOpenLatestAUSAgreement();
  const { enqueueSnackbar } = useSnackbar();
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureUploading, setProfilePictureUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { firebaseUser } = useFirebaseContext();
  const [profilePictureUploadPercentage, setProfilePictureUploadPercentage] =
    useState(0);
  const initialTouchedFields: Set<string> = new Set();
  const userNames = firebaseUser?.displayName?.split(" ");
  const inputRef = useRef<HTMLInputElement>(null);
  const { authContext, logout, refreshLoginClaims, refreshProfilePictureURL } =
    useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();

  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm<FormState, FormUpdaterSpecsType>(
      validationFunc,
      async (): Promise<FormState> => {
        return {
          firstName: userNames ? userNames[0] : "",
          lastName: userNames ? userNames[userNames.length - 1] : "",
          acceptPaasAndAusa: false,
          profilePictureURL: "",
        };
      },
      formUpdaterSpecs,
      initialState,
      initialTouchedFields,
    );

  // retrieve user's Mesh profile picture or set the firebase profile picture
  // as the Mesh profile picture
  const getProfilePictureDownloadURL = useCallback(async () => {
    setProfilePictureUploading(true);

    // try and get a profile picture url from Mesh
    let profilePictureURL = "";
    try {
      profilePictureURL = (
        await UserManagerRead.GetMyProfilePictureDownloadURL({
          context: authContext,
        })
      ).url;
    } catch (e: unknown) {
      console.debug(`error getting profile picture url: ${e}`);
    }

    // if this url is still blank then try and get one from firebase
    let profilePictureData: Buffer | undefined;
    let profilePictureUploadURL = "";
    if (profilePictureURL === "") {
      try {
        const getFirebaseProfilePictureResponse =
          await ProfilePictureController.GetFirebaseProfilePicture({
            context: authContext,
          });
        if (
          getFirebaseProfilePictureResponse.encodedProfilePicture &&
          getFirebaseProfilePictureResponse.encodedProfilePicture.length > 0
        ) {
          profilePictureData = Buffer.from(
            getFirebaseProfilePictureResponse.encodedProfilePicture,
            "base64",
          );
          profilePictureUploadURL = getFirebaseProfilePictureResponse.uploadURL;
        }
      } catch (e: unknown) {
        console.debug(`error getting profile picture data: ${e}`);
      }

      // if at this point the profile picture data was retrieved then try and upload it to firebase
      if (profilePictureData && profilePictureUploadURL !== "") {
        try {
          const result =
            await ProfilePictureController.GetFirebaseProfilePicture({
              context: authContext,
            });
          doUpload({
            url: result.uploadURL,
            documentType: result.mimeType,
            documentData: Buffer.from(result.encodedProfilePicture, "base64"),
            onComplete: () => {
              setProfilePictureUploading(false);
              setProfilePictureUploadPercentage(0);
              refreshProfilePictureURL();
              formUpdater.profilePictureUrl(
                firebaseUser?.photoURL ? firebaseUser?.photoURL : "",
              );
            },
            onProgress: (percentageComplete: number) =>
              setProfilePictureUploadPercentage(percentageComplete),
            onError: (e) => {
              console.error(e);
            },
          });
        } catch (e: unknown) {
          console.error(`error uploading profile picture: ${e}'`);
        }
      }
    }
    return profilePictureURL;
  }, []);

  useEffect(() => {
    // update the formState with Mesh profile picture url
    getProfilePictureDownloadURL()
      .then((result) => {
        if (result) {
          formUpdater.profilePictureUrl(result);
        }
      })
      .finally(() => {
        setLoading(false);
        setProfilePictureUploading(false);
      });
  }, []);

  const { fieldValidations } = validationResult;

  //complete client profile
  const handleSetupClientProfile = useCallback(async () => {
    setLoading(true);
    try {
      // setup individual client profile
      await ClientProfileAdministrator.SetupIndividualClientProfile({
        context: authContext,
        firstName: formState.firstName,
        lastName: formState.lastName,
      });
      await refreshLoginClaims();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Setting Up Profile: ${err.message}`, {
        variant: "error",
      });
      console.error(
        `error setting up individual client profile: ${
          err.message ? err.message : err.toString()
        }`,
      );
      setLoading(false);
    }
  }, [authContext, formState]);

  //retrieve user's Mesh profile picture
  const retrieveUserProfilePicture = async () => {
    setLoading(true);
    try {
      formUpdater.profilePictureUrl(
        (
          await UserManagerRead.GetMyProfilePictureDownloadURL({
            context: authContext,
          })
        ).url,
      );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      if (err.message && err.message !== "not found") {
        enqueueSnackbar("Unable not get user profile picture", {
          variant: "error",
        });
        console.error(
          "error getting user profile picture:",
          err.message ? err.message : err.toString(),
        );
      }
    }
    setLoading(false);
  };

  //upload user's profile picture
  const handleSelectPictureFile = async () => {
    let uploadInputRef;
    if (inputRef.current) {
      uploadInputRef = inputRef.current;
    }

    try {
      // confirm that a file has been selected
      if (
        !(uploadInputRef && uploadInputRef.files && uploadInputRef.files.length)
      ) {
        console.error("no upload input ref files");
        enqueueSnackbar("No upload file selected", { variant: "error" });
        setLoading(false);
        return;
      }

      // get a reference to the selected file and confirm it is not null
      const fileToUpload = uploadInputRef.files[0];
      if (!fileToUpload) {
        console.error("file to upload is null");
        enqueueSnackbar("File to upload is null", { variant: "error" });
        setLoading(false);
        return;
      }

      const fileSplit = fileToUpload.name.split(".");
      if (fileSplit.length < 2) {
        console.error("invalid file type");
        enqueueSnackbar("Invalid file type", { variant: "error" });
        setLoading(false);
        return;
      }

      const ext = fileSplit[fileSplit.length - 1];
      switch (ext.toLowerCase()) {
        case "png":
        case "jpg":
        case "jpeg":
          break;
        default:
          console.error("invalid file type");
          enqueueSnackbar("Invalid file type", { variant: "error" });
          setLoading(false);
          return;
      }

      // file size should not exceed 5 MB
      if (fileToUpload.size > 5000000) {
        console.error("file cannot be larger than 5 MB");
        enqueueSnackbar("File cannot be larger than 5 MB", {
          variant: "error",
        });
        setLoading(false);
        return;
      }

      setProfilePictureUploading(true);
      setProfilePictureUploadPercentage(0);

      // construct a file reader
      // read file
      // perform upload
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const fileData: ArrayBuffer | null =
          fileReader.result as ArrayBuffer | null;
        if (fileData === null) {
          console.error("file data is null");
          enqueueSnackbar("File data is null", { variant: "error" });
          setLoading(false);
          return;
        }

        const uploadURL = (
          await UserManagerRead.GetMyProfilePictureUploadURL({
            context: authContext,
          })
        ).url;
        doUpload({
          url: uploadURL,
          documentType: fileToUpload.type,
          documentData: fileData,
          onComplete: () => {
            setProfilePictureUploading(false);
            setProfilePictureUploadPercentage(0);
            retrieveUserProfilePicture().finally();
          },
          onProgress: (percentageComplete: number) =>
            setProfilePictureUploadPercentage(percentageComplete),
        });
      };

      fileReader.readAsArrayBuffer(uploadInputRef.files[0]);
    } catch (e) {
      console.error("error trying to access picture file", e);
    }
  };

  //delete user's profile picture
  const handleDeleteProfilePicture = async () => {
    setLoading(true);
    try {
      await ProfilePictureController.DeleteProfilePicture({
        context: authContext,
      });
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    //clear profile picture url from formState
    formUpdater.profilePictureUrl("");
    setLoading(false);
  };

  return (
    <OnboardingCard
      dataComponentAttribute={JSON.stringify({
        component_id: "account_creation",
        component_business_name: "account creation",
        component_title: "lets create your account",
        component_driver: InteractionDriver.profileCreation,
      } as DataComponentInfo)}
      linksFooter={
        <Link
          id="setupIndividualClientCard-logout-link"
          sx={(theme) => ({
            fontWeight: "bold",
            paddingBottom: theme.spacing(5.5),
          })}
          onClick={async () => {
            await logout();
            navigate("/sign-in");
          }}
          fontSize={"16px"}
          component="button"
          underline="hover"
          color="secondary"
          data-link-info={JSON.stringify({
            content_interaction_id: "account-creation-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_type: InteractionType.Link,
            content_interaction_text: "logout",
            content_interaction_driver: InteractionDriver.Logout,
          } as DataLinkInfoType)}
        >
          Logout
        </Link>
      }
    >
      <VerticalSlide transitionDuration={1 / 6}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 0, 0.5),
          })}
          alignSelf="center"
          fontSize="20px"
          fontWeight="600"
        >
          Let&apos;s create your account
        </Typography>
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 6}>
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(3),
          })}
        >
          {loading || profilePictureUploading ? (
            <Box
              sx={{
                width: 70,
                height: 70,
                padding: 0,
                margin: 0,
              }}
            >
              <CircularProgress
                size={55}
                variant={
                  profilePictureUploadPercentage
                    ? "determinate"
                    : "indeterminate"
                }
                value={profilePictureUploadPercentage}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {formState.profilePictureURL && (
                <IconButton disableRipple onClick={handleDeleteProfilePicture}>
                  <DeleteIcon />
                </IconButton>
              )}
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <IconButton
                    disableRipple
                    onClick={() => {
                      if (inputRef.current != null) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    <AddCircleIcon
                      sx={(theme) => ({
                        background: `radial-gradient(circle, #FFFFFF 0%, ${theme.palette.secondary.main} 70%, ${theme.palette.secondary.main} 100%)`,
                        borderRadius: "100%",
                        p: 0,
                      })}
                      color="secondary"
                    />
                  </IconButton>
                }
              >
                <Avatar
                  id="setupIndividualClientProfile-profilePic-button"
                  sx={(theme) => ({
                    width: 70,
                    height: 70,
                    padding: 0,
                    margin: 0,
                    backgroundColor: theme.palette.custom.grape,
                    color: theme.palette.text.primary,
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  })}
                  onClick={() => {
                    if (inputRef.current != null) {
                      inputRef.current.click();
                    }
                  }}
                >
                  {formState.profilePictureURL ? (
                    <img
                      onLoad={() => {
                        if (profilePictureRef.current) {
                          setProfilePictureVertical(
                            profilePictureRef.current.naturalWidth <
                              profilePictureRef.current.naturalHeight,
                          );
                        }
                      }}
                      style={{
                        width: !profilePictureVertical ? 100 : 70,
                        height: profilePictureVertical ? 100 : 70,
                      }}
                      ref={profilePictureRef}
                      alt=""
                      src={formState.profilePictureURL}
                    />
                  ) : (
                    <PersonIcon sx={{ width: 60, height: 60 }} />
                  )}
                </Avatar>
              </Badge>
              {formState.profilePictureURL && (
                <IconButton
                  disableRipple
                  onClick={() => {
                    if (inputRef.current != null) {
                      inputRef.current.click();
                    }
                  }}
                >
                  <ReloadIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={3 / 6}>
        <Box
          sx={{
            width: "312px",
            height: "24px",
          }}
        >
          <input
            id={"mobile-hidden-input"}
            style={{
              visibility: "hidden",
              width: 0,
              height: 0,
              margin: 0,
              padding: 0,
            }}
            disabled={loading}
            ref={inputRef}
            onChange={handleSelectPictureFile}
            type="file"
            accept=".png,.jpg,.jpeg"
          />
        </Box>
        <TextField
          sx={(theme) => ({
            paddingBottom: theme.spacing(2),
          })}
          fullWidth
          id="setupIndividualClientCard-firstName-formField"
          disabled={loading}
          variant="outlined"
          label="First Name"
          value={formState.firstName}
          error={!!fieldValidations.firstName}
          helperText={fieldValidations.firstName}
          onChange={(e) => {
            formUpdater.firstName(e.target.value);
          }}
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={4 / 6}>
        <TextField
          sx={{
            margin: 0,
          }}
          fullWidth
          id="setupIndividualClientCard-lastName-formField"
          disabled={loading}
          variant="outlined"
          label="Last Name"
          value={formState.lastName}
          error={!!fieldValidations.lastName}
          helperText={fieldValidations.lastName}
          onChange={(e) => {
            formUpdater.lastName(e.target.value);
          }}
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={5 / 6}>
        <FormControlLabel
          id="setupIndividualClientCard-formControlLabel"
          sx={(theme) => ({
            width: "308px",
            padding: theme.spacing(3, 0, 3),
            margin: 0,
          })}
          control={
            <Checkbox
              disabled={loading}
              id="paas-ausa-mesh-terms-and-conditions-checkBox"
              checked={formState.acceptPaasAndAusa}
              onChange={() => {
                if (formState.acceptPaasAndAusa) {
                  formUpdater.acceptPaasAndAusa(false);
                  return;
                }
                formUpdater.acceptPaasAndAusa(true);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label={
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.tertiary,
              })}
            >
              I have read and accept the Mesh
              <br />
              <Link
                onClick={openLatestPaaSAgreement}
                target="_blank"
                sx={{
                  cursor: "pointer",
                }}
                underline="none"
                color="secondary"
              >
                PAAS
              </Link>
              &nbsp;&&nbsp;
              <Link
                id="setupIndividualClientCard-termAndConditions-link"
                onClick={openLatestAUSAgreement}
                target="_blank"
                sx={{
                  cursor: "pointer",
                }}
                underline="none"
                color="secondary"
              >
                AUSA
              </Link>{" "}
              terms and conditions.
            </Typography>
          }
        />
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          sx={(theme) => ({
            height: theme.spacing(6),
            width: "100%",
            marginBottom: theme.spacing(8.5),
          })}
          id="setupIndividualClientCard-submit-button"
          disabled={
            !formState.firstName ||
            !formState.lastName ||
            !formState.acceptPaasAndAusa ||
            validationInProgress ||
            loading
          }
          onClick={handleSetupClientProfile}
          variant="contained"
          color="primary"
          size="large"
          data-link-info={JSON.stringify({
            content_interaction_id: "account-creation-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_type: InteractionType.Button,
            content_interaction_text: "submit",
            content_interaction_driver:
              InteractionDriver.SubmitProfileInformation,
          } as DataLinkInfoType)}
        >
          Submit
        </Button>
      </VerticalSlide>
    </OnboardingCard>
  );
}
