import React, { useEffect } from "react";
import {
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
  Button,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { DateField, TextField } from "components/FormFields";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { Client } from "james/client";
import { usePersonViewStore } from "./store";
import HistoryIcon from "@mui/icons-material/History";
import { useSavePersonDetails } from "./hooks/useSaveUpdatePersonDetails";
import { AllIdentificationTypes, IdentificationType } from "james/legal";
import {
  SourceOfIncomeAndWealthType,
  ValidSourceOfIncomeTypes,
  ValidSourceOfWealthTypes,
} from "james/legal/person";
import { InfluentialPersonStatus } from "james/legal/person/Person";
import { Address } from "james/location";
import { CountryDropdown } from "components/FormFields/CountryDropdown";

interface PersonTabProps {
  client: Client;
  readOnly?: boolean;
}

export const PersonTab = ({ client, readOnly }: PersonTabProps) => {
  const store = usePersonViewStore();
  useInitialDataFetch(client);
  const { savePersonDetails } = useSavePersonDetails();

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <Box>
      <Backdrop open={store.loading}>
        <CircularProgress size={100} />
      </Backdrop>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          paddingBottom: (theme) => theme.spacing(2),
          paddingTop: (theme) => theme.spacing(4),
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Backdrop open={store.loading}>
          <CircularProgress size={100} />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            {client.name}
          </Typography>
          <HistoryIcon color={"disabled"} />
          <div>
            <Typography variant={"body2"} color="textSecondary">
              {`Last update ${dayjs(store.lastUpdateDate).format(
                "YYYY-MM-DD, HH:mm A",
              )}`}
            </Typography>
            <Typography variant={"caption"} color="textSecondary">
              {store.lastUpdateBy}
            </Typography>
          </div>
        </Box>
        {!readOnly && (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={async () => {
                store.setLoading(true);
                await savePersonDetails();
                store.setLoading(false);
              }}
              disabled={store.loading}
              variant={"contained"}
              color={"primary"}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>

      <>
        <Typography variant="h5" fontWeight="bold" sx={{ mt: 3 }}>
          Personal Details
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            columnGap: 3,
            "& > *": {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Box>
            <TextField
              label="First Name"
              readOnly={readOnly}
              value={store.firstName}
              onChange={(e) => store.setFirstName(e.target.value)}
            />
            <TextField
              label="Middle Names"
              readOnly={readOnly}
              value={store.middleNames}
              onChange={(e) => store.setMiddleNames(e.target.value)}
            />
            <TextField
              label="Last Name"
              readOnly={readOnly}
              value={store.lastName}
              onChange={(e) => store.setLastName(e.target.value)}
            />
            <TextField
              label="Nationality"
              readOnly={readOnly}
              value={store.nationality}
              onChange={(e) => store.setNationality(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              select
              readOnly={readOnly}
              label="Identification Type"
              value={store.identificationType}
              onChange={(v) =>
                store.setIdentificationType(
                  v.target.value as IdentificationType,
                )
              }
            >
              {AllIdentificationTypes.map((v, idx) => {
                return (
                  <MenuItem key={idx} value={v}>
                    {v}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              readOnly={readOnly}
              label="Identification Number"
              value={store.identificationNumber}
              onChange={(e) => store.setIdentificationNumber(e.target.value)}
            />
            <DateField
              readOnly={readOnly}
              label="Identification Number Expiry"
              value={
                store.identificationNumberExpiry
                  ? dayjs(store.identificationNumberExpiry).format("YYYY/MM/DD")
                  : ""
              }
              onChange={(v) =>
                store.setIdentificationNumberExpiry(
                  dayjs(v).format("YYYY/MM/DD"),
                )
              }
            />
          </Box>
          <Box>
            <TextField
              select
              readOnly={readOnly}
              label="Source Of Income"
              value={store.sourceOfIncome}
              onChange={(v) =>
                store.setSourceOfWealth(
                  v.target.value as SourceOfIncomeAndWealthType,
                )
              }
            >
              {ValidSourceOfIncomeTypes.map((v, idx) => {
                return (
                  <MenuItem key={idx} value={v}>
                    {v}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              label="Source Of Wealth"
              readOnly={readOnly}
              value={store.sourceOfWealth}
              select
              onChange={(v) =>
                store.setSourceOfWealth(
                  v.target.value as SourceOfIncomeAndWealthType,
                )
              }
            >
              {ValidSourceOfWealthTypes.map((v, idx) => {
                return (
                  <MenuItem key={idx} value={v}>
                    {v}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              select
              label="Influential Person"
              readOnly={readOnly}
              value={store.influentialPerson}
              onChange={(e) =>
                store.setInfluentialPerson(
                  e.target.value as InfluentialPersonStatus,
                )
              }
            >
              <MenuItem value={InfluentialPersonStatus.InfluentialPersonStatus}>
                Yes
              </MenuItem>
              <MenuItem
                value={InfluentialPersonStatus.NotInfluentialPersonStatus}
              >
                No
              </MenuItem>
            </TextField>
          </Box>
        </Box>
      </>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          columnGap: 3,
          mt: 3,
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Residential Address
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 3,
            }}
          >
            <TextField
              readOnly={readOnly}
              label="Address Line 1"
              value={store.residentialAddress.addressLine1}
              onChange={(e) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    addressLine1: e.target.value,
                  }),
                )
              }
            />
            <TextField
              readOnly={readOnly}
              label="Address Line 2"
              value={store.residentialAddress.addressLine2}
              onChange={(e) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    addressLine2: e.target.value,
                  }),
                )
              }
            />
            <TextField
              readOnly={readOnly}
              label="Postal Code"
              value={store.residentialAddress.postalCode}
              onChange={(e) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    postalCode: e.target.value,
                  }),
                )
              }
            />
            <TextField
              readOnly={readOnly}
              label="City"
              value={store.residentialAddress.city}
              onChange={(e) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    city: e.target.value,
                  }),
                )
              }
            />
            <TextField
              readOnly={readOnly}
              label="Suburb"
              value={store.residentialAddress.suburb}
              onChange={(e) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    suburb: e.target.value,
                  }),
                )
              }
            />
            <TextField
              readOnly={readOnly}
              label="Province"
              value={store.residentialAddress.province}
              onChange={(e) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    province: e.target.value,
                  }),
                )
              }
            />
            <CountryDropdown
              readOnly={readOnly}
              renderInput={(parrams) => {
                return <TextField {...parrams} />;
              }}
              onChange={(e, v) =>
                store.setResidentialAddress(
                  new Address({
                    ...store.residentialAddress,
                    countryCode: v?.value ?? "",
                  }),
                )
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Contact Details
          </Typography>
          <Divider sx={{ my: 1 }} />
          <TextField
            label="Cellphone Number"
            readOnly={readOnly}
            value={store.contactDetails.cellphoneNumber.value}
          />
          <TextField
            label="Telephone Number"
            readOnly={readOnly}
            value={store.contactDetails.telephoneNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (
                /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                value.length === 0
              ) {
                store.setContactDetails({
                  ...store.contactDetails,
                  telephoneNumber: e.target.value,
                });
              }
            }}
          />
          <TextField
            label="Email Address"
            readOnly={readOnly}
            value={store.contactDetails.emailAddress}
            disabled
            onChange={(e) =>
              store.setContactDetails({
                ...store.contactDetails,
                emailAddress: e.target.value,
              })
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
