export class ContactDetails {
  public emailAddress = "";

  public telephoneNumber = "";

  public cellphoneNumber = new CellphoneNumber();

  constructor(contactDetails?: ContactDetails) {
    if (!contactDetails) {
      return;
    }
    this.emailAddress = contactDetails.emailAddress;
    this.telephoneNumber = contactDetails.telephoneNumber;
    this.cellphoneNumber = contactDetails.cellphoneNumber;
  }
}

export class CellphoneNumber {
  public value = "";
  public verified? = false;

  constructor(cellphoneNumber?: CellphoneNumber) {
    if (!cellphoneNumber) {
      return;
    }
    this.value = cellphoneNumber.value;
    this.verified = cellphoneNumber.verified;
  }
}
