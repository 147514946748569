import React, { useState } from "react";
import {
  RowType,
  BEScrollTable,
  DataRequest,
  NoDataSplash,
} from "@mesh/common-js-react/dist/Tables";
import {
  Button,
  Tab,
  Tabs,
  Theme,
  Typography,
  Box,
  useMediaQuery,
  Card,
  CardContent,
  CardActionArea,
  ButtonGroup,
  alpha,
  TableCell,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import { Model } from "@mesh/common-js/dist/views/marketLimitOrderView/model_pb";
import {
  FilterMap,
  useSearchLimitOrderView,
} from "../../hooks/useSearchLimitOrderView";
import { limitOrderTypeToString } from "@mesh/common-js/dist/market/limitOrderType";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { limitOrderStateToString } from "@mesh/common-js/dist/market/limitOrderState";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { newUint32ListCriterion } from "@mesh/common-js/dist/search";
import { Criteria } from "@mesh/common-js/dist/search/Criteria";
import { LimitOrderState } from "@mesh/common-js/dist/market/limitOrder_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/src/i8n/timezone_pb";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { TabOption, useExchangeDashboardStore } from "../../store";
import { CancelConfirmationDialog } from "./components/CancelConfirmationDialog";
import InfoIcon from "@mui/icons-material/Info";

export interface MyOffersProps {
  loading: boolean;
  searchingOffers: boolean;
  selectedTab: TabOption;
  marketLimitOrderViewModels: Model[];
  total: number;
  cancelingOrder: boolean;
}

export interface MyOffersActions {
  setSelectedTab: (val: TabOption) => void;
  setMarketLimitOrderViewModels: (val: Model[]) => void;
  setTotal: (val: number) => void;
  setLoading: (val: boolean) => void;
  setSearchingOffers: (val: boolean) => void;
  setCancelingOrder: (val: boolean) => void;
}

export const MyOffers = ({
  selectedTab,
  marketLimitOrderViewModels,
  total,
  setSelectedTab,
  searchingOffers,
}: MyOffersProps & MyOffersActions) => {
  const { searchMarketLimitOrderView } = useSearchLimitOrderView();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box>
      <BEScrollTable
        requestFunction={async (request: DataRequest) => {
          try {
            const response = await searchMarketLimitOrderView(request);
            if (!response)
              return {
                models: [],
                total: 0,
              };
            return {
              models: response.getRecordsList(),
              total: response.getTotal(),
            };
          } catch (e) {
            console.error(e);
          }
          return {
            models: [],
            total: 0,
          };
        }}
        columns={columns}
        title={""}
        refresh={() => {
          setSelectedTab(TabOption.MyOffers);
        }}
        noDataSplash={
          <NoDataSplash
            icon={false}
            title={"No orders yet!"}
            body={
              selectedTab === TabOption.History
                ? "Once an order is complete, it will appear here"
                : "Once you place an order, it will appear here."
            }
          />
        }
        filters={(setCriteria, setQuery): React.ReactNode =>
          smDown ? (
            <ButtonGroup
              fullWidth
              className={selectedTab}
              sx={(theme) => ({
                mt: 2,
                px: 2,
                "&.MyOffers .history": {
                  backgroundColor: alpha("#000000", 0.32),
                  color: theme.palette.text.disabled,
                },
                "&.History .open-orders": {
                  backgroundColor: alpha("#000000", 0.32),
                  color: theme.palette.text.disabled,
                },
              })}
            >
              <Button
                onClick={() => {
                  setQuery(new Query().setOffset(0).setLimit(10));
                  setCriteria(
                    new Criteria({
                      state: newUint32ListCriterion(
                        "state",
                        FilterMap[TabOption.MyOffers],
                      ),
                    }),
                  );
                  setSelectedTab(TabOption.MyOffers);
                }}
                className="open-orders"
                variant="contained"
                color="secondary"
              >
                Open Orders
              </Button>
              <Button
                onClick={() => {
                  setQuery(new Query().setOffset(0).setLimit(10));
                  setCriteria(
                    new Criteria({
                      state: newUint32ListCriterion(
                        "state",
                        FilterMap[TabOption.History],
                      ),
                    }),
                  );
                  setSelectedTab(TabOption.History);
                }}
                className="history"
                variant="contained"
                color="primary"
              >
                History
              </Button>
            </ButtonGroup>
          ) : (
            <Tabs
              textColor={"inherit"}
              value={selectedTab}
              onChange={(e, v) => {
                setSelectedTab(v as TabOption);
                setQuery(new Query().setOffset(0).setLimit(10));
                setCriteria(
                  new Criteria({
                    state: newUint32ListCriterion(
                      "state",
                      FilterMap[v as TabOption],
                    ),
                  }),
                );
              }}
            >
              <Tab
                sx={{
                  textTransform: "capitalize",
                }}
                value={TabOption.MyOffers}
                label="Open Orders"
              />
              <Tab
                sx={{
                  textTransform: "capitalize",
                }}
                value={TabOption.History}
                label="History"
              />
            </Tabs>
          )
        }
        loading={searchingOffers}
        interval={10}
        data={marketLimitOrderViewModels}
        total={total}
        criteria={
          selectedTab === TabOption.MyOffers
            ? new Criteria({
                state: newUint32ListCriterion("state", [
                  LimitOrderState.SUBMISSION_IN_PROGRESS,
                  LimitOrderState.CANCELLATION_IN_PROGRESS,
                  LimitOrderState.COMPLETE_IN_PROGRESS,
                  LimitOrderState.OPEN,
                ]),
              })
            : new Criteria({
                state: newUint32ListCriterion("state", [
                  LimitOrderState.COMPLETE,
                  LimitOrderState.SUBMISSION_FAILED,
                ]),
              })
        }
        query={new Query().setLimit(10).setOffset(0)}
        rowComponent={
          smDown
            ? (data) => {
                const d = data as Model;
                return (
                  <Card
                    sx={(theme) => ({
                      mx: 1,
                      my: 1.5,
                      backgroundColor: theme.palette.custom.cardInner,
                    })}
                  >
                    <CardContent
                      sx={{
                        "& > *": {
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <Box>
                        <Typography>Pair</Typography>
                        <Typography>
                          {d.getBaseamount()?.getToken()?.getCode()}/
                          {d.getPrice()?.getToken()?.getCode()}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>Side</Typography>
                        <Typography>
                          {limitOrderTypeToString(d.getType())}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>Ordered Price</Typography>
                        <Amount
                          amount={AmountType.fromFutureAmount(
                            d.getQuoteamount() ?? new FutureAmount(),
                          )}
                        />
                      </Box>
                      <Box>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography>Filled Amount</Typography>
                          <Tooltip
                            title={
                              "The amount of the order that has been filled denominated in the base currency"
                            }
                          >
                            <IconButton sx={{ p: 0, ml: 1 }}>
                              <InfoIcon
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <Amount
                          amount={AmountType.fromFutureAmount(
                            d.getAmountfilled() ?? new FutureAmount(),
                          )}
                        />
                      </Box>
                      <Box>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography>Fill Price</Typography>
                          <Tooltip
                            title={
                              "A weighted-average price based on all the successfully filled legs of a trade"
                            }
                          >
                            <IconButton sx={{ p: 0, ml: 1 }}>
                              <InfoIcon
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <Amount
                          amount={AmountType.fromFutureAmount(
                            d.getFillprice() ?? new FutureAmount(),
                          )}
                        />
                      </Box>
                      <Box>
                        <Typography>Amount</Typography>
                        <Amount
                          amount={AmountType.fromFutureAmount(
                            d.getBaseamount() ?? new FutureAmount(),
                          )}
                        />
                      </Box>
                      <Box>
                        <Typography>Condition</Typography>
                        <Typography>-</Typography>
                      </Box>
                      <Box>
                        <Typography>State</Typography>
                        <Typography>
                          {limitOrderStateToString(d.getState())}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>Date</Typography>
                        <Typography>
                          {protobufTimestampToDayjs(
                            d.getSubmittedat() ?? new Timestamp(),
                          ).format("DD MMM YYYY, HH:MM:ss")}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CancelButton model={d} />
                  </Card>
                );
              }
            : undefined
        }
        sx={(theme: Theme) => ({
          height: {
            sm: 260,
            xs: "calc(100vh - 180px)",
          },
          backgroundColor: {
            xs: theme.palette.background.default,
            sm: theme.palette.custom.cardInner,
          },
          borderRadius: {
            sm: "8px",
            xs: 0,
          },
          ".table-body": {
            backgroundColor: {
              xs: theme.palette.background.default,
              sm: theme.palette.custom.cardInner,
            },
          },
          ".header-cell": {
            backgroundColor: theme.palette.custom.cardInner,
            py: 0.5,
          },
          ".table-row-cell": {
            height: "50px",
          },
        })}
      />
    </Box>
  );
};

const CancelButton = (props: { model: Model }) => {
  const store = useExchangeDashboardStore();
  const [openDialog, setOpenDialog] = useState(false);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (store.openOrdersState.selectedTab === TabOption.History) return null;
  return (
    <>
      {!smDown ? (
        <Button
          variant="outlined"
          disabled={
            store.openOrdersState.cancelingOrder ||
            props.model.getState() != LimitOrderState.OPEN ||
            store.loading ||
            !store.tradeCardState.isSignatory
          }
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Cancel
        </Button>
      ) : (
        <CardActionArea
          sx={(theme: Theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 42,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: theme.palette.custom.grape,
          })}
          disabled={
            store.openOrdersState.cancelingOrder ||
            props.model.getState() != LimitOrderState.OPEN ||
            store.loading
          }
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Cancel
        </CardActionArea>
      )}
      <CancelConfirmationDialog
        open={openDialog}
        closeDialog={() => setOpenDialog(false)}
        model={props.model}
      />
    </>
  );
};

type ColumnType = {
  pair: string;
  type: string;
  price: string;
  amountfilled: string;
  baseamount: string;
  quoteamount: string;
  state: string;
  submittedat: string;
  fillprice: string;
};

const columns: RowType<Model, ColumnType> = {
  pair: {
    title: "Pair",
    renderCell: (rowData: Model) => {
      return (
        <Typography>
          {rowData.getBaseamount()?.getToken()?.getCode()}/
          {rowData.getPrice()?.getToken()?.getCode()}
        </Typography>
      );
    },
  },
  type: {
    title: "Side",
    renderCell: (rowData: Model) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            className={limitOrderTypeToString(rowData.getType())}
            sx={(theme) => ({
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              "&.Sell": {
                backgroundColor: theme.palette.error.main,
              },
              "&.Buy": {
                backgroundColor: theme.palette.success.main,
              },
            })}
          />
          <Typography>{limitOrderTypeToString(rowData.getType())}</Typography>
        </Box>
      );
    },
  },
  price: {
    title: "Price",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getPrice())} />
      );
    },
  },
  fillprice: {
    title: (
      <TableCell className="header-cell" sx={{ borderBottom: "none", pl: 0 }}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          Fill&nbsp;Price
          <Tooltip
            title={
              "A weighted-average price based on all the successfully filled legs of a trade"
            }
          >
            <IconButton sx={{ p: 0, ml: 0.5 }}>
              <InfoIcon
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    ),
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getFillprice())} />
      );
    },
  },
  amountfilled: {
    title: (
      <TableCell className="header-cell" sx={{ borderBottom: "none", pl: 0 }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          Amount&nbsp;Filled
          <Tooltip
            title={
              "The amount of the order that has been filled denominated in the base currency"
            }
          >
            <IconButton sx={{ p: 0, ml: 0.5 }}>
              <InfoIcon
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    ),
    renderCell: (rowData: Model) => {
      return (
        <Amount
          amount={AmountType.fromFutureAmount(rowData.getAmountfilled())}
        />
      );
    },
  },
  baseamount: {
    title: "Base Amount",
    renderCell: (rowData: Model) => {
      return (
        <Amount amount={AmountType.fromFutureAmount(rowData.getBaseamount())} />
      );
    },
  },
  quoteamount: {
    title: (
      <TableCell className="header-cell" sx={{ borderBottom: "none", pl: 0 }}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          Quote&nbsp;Amount
          <Tooltip title={"The order amount denominated in the quote currency"}>
            <IconButton sx={{ p: 0, ml: 0.5 }}>
              <InfoIcon
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    ),
    renderCell: (rowData: Model) => {
      return (
        <Amount
          amount={AmountType.fromFutureAmount(rowData.getQuoteamount())}
        />
      );
    },
  },
  state: {
    title: "Status",
    renderCell: (rowData: Model) => {
      return limitOrderStateToString(rowData.getState());
    },
  },
  submittedat: {
    title: "Date",
    renderCell: (rowData: Model) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            {protobufTimestampToDayjs(
              rowData.getSubmittedat() ?? new Timestamp(),
            )
              .tz(timezoneToString(Timezone.SAST_TIMEZONE))
              .format("DD MMM YYYY, HH:mm:ss")}
          </Typography>
          <CancelButton model={rowData} />
        </Box>
      );
    },
  },
};
