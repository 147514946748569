import { Client } from "james/client";
import { usePersonViewStore } from "../store";
import { useEffect } from "react";
import { useErrorContext } from "context/Error";
import { usePersonContext } from "context/Person/PersonService";
import { personToPast } from "james/legal/person/Person";

export const useInitialDataFetch = (client: Client) => {
  const store = usePersonViewStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { listPersons } = usePersonContext();

  useEffect(() => {
    setTimeout(async () => {
      try {
        store.setLoading(true);
        const personsList = await listPersons();
        const person = personToPast(
          personsList.filter((p) => p.getId() === client.legalEntityID)[0],
        );

        store.setLastUpdateBy(person.auditEntry.userID);
        store.setLastUpdateDate(person.auditEntry.time);
        store.setFromState(person);
        store.setCachedPerson(person);
      } catch (e) {
        store.setLoading(false);
        const err = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(err);
      }
      store.setLoading(false);
    });
  }, []);

  return {};
};
